@use '../../../assets/sass/others/variables' as theme;

@use "sass:map";

$color_1: rgb(243, 104, 74);
$color_2: black;
$color_3: rgba(0, 0, 0, 0.6);

.ai-operation{

    .introduction-section {
    
        display: flex;
        width: 100%;
        padding-top: 150px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    display: flex;
                    flex-direction: row;
                    font-family: Raleway;
                    font-weight: 400;
                    margin-top: 40px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.782);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                    right: 0px;
                    transform: scale(-1);
                }
    
                .circle-decoration:nth-child(2) {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    left: -15px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    height: auto;
                    width: 750px;
                }
            }

            .botton-container {
                margin-top: 60px !important;
            }
        }
    
    }

    .outcome-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgb(249, 249, 249);
        padding-bottom: 100px;
        padding-top: 50px;
    
        .section-container {
            max-width: 1600px; 
            margin: auto; 
            width: 100%;
    
            .container-head {
                display: flex;
                flex-direction: column;
                max-width: 1204px;
                margin: auto;

    
                .title {
                    color: black;
                    font-size: 18px;
                    font-family: Raleway;
                    font-weight: 400;
                    overflow-wrap: break-word;
                    text-align: center;
                }
                
                .subtitle {
                    font-family: Raleway;
                    font-weight: 500;
                    font-size: 50px;
                    text-align: center;
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .subtitle:nth-child(2){
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                }
    
            }
            
            .container-content {
                display: flex;
                flex-flow: wrap;
                width: 1070px;
                margin: 100px auto auto;
    
                .content-item {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 500px;
                    height: 262px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: map.get(theme.$colors, "white");
                    border-radius: 6px;
                    padding: 20px;
                    box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
    
                    .title {
                        font-family: Raleway; font-weight: 600; font-size: 24px; text-align: left; color: rgb(58, 49, 76); padding-top: 60px;
                    }
                }
                
                .item-icon {
                    margin-left: 15px;
                    position: absolute;
                    margin-top: -60px;
                    width: 70px;
                    height: 70px;
                }
    
            }
            
        }
    
    }

    .conclusion-section {
    
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 80px;
                padding-right: 40px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-right: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1; 
                    top: 70px;
                    left: 0px;
                }
    
                .circle-decoration:nth-child(2) {
                    width:  70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    right: 45px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
        }
    
    }
    
}


@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .ai-operation{

        .introduction-section {
        
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #F9F9F9;
        
            .section-container {
                width: 100%;
                height:  350px;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    min-width: 440px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 130%;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1;
                        top: 50px;
                        right: 0px;
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
        
                    .circle-decoration:nth-child(2) {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        bottom: 20px;
                        left: -15px;
                    }
        
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        width: 450px ;
                        height:  350px ;
                    }
                }
            }
        
        }

        .outcome-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgb(249, 249, 249);
            padding-bottom: 100px;
            padding-top: 50px;
        
            .section-container {
                max-width: map-get(theme.$brakpoint, 'lg'); 
                margin: auto; 
                width: 100%;
        
                .container-head {
                    display: flex;
                    flex-direction: column;
                    max-width: 1204px;
                    margin: auto;
    
        
                    .title {
                        color: black;
                        font-size: 18px;
                        font-family: Raleway;
                        font-weight: 400;
                        overflow-wrap: break-word;
                        text-align: center;
                    }
                    
                    .subtitle {
                        font-family: Raleway;
                        font-weight: 500;
                        font-size: 40px;
                        text-align: center;
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .subtitle:nth-child(2){
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: center;
                    }
        
                }
                
                .container-content {
                    display: flex;
                    flex-flow: wrap;
                    width: 1070px;
                    margin: 100px auto auto;
        
                    .content-item {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: left;
                        line-height: 1.5;
                        width: 450px;
                        min-height: 245px;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 20px;
                        margin-bottom: 30px;
                        margin-top: 30px;
                        background-color: map.get(theme.$colors, "white");
                        border-radius: 6px;
                        padding: 20px;
                        box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
        
                        .title {
                            font-family: Raleway; font-weight: 600; font-size: 22px; text-align: left; color: rgb(58, 49, 76); padding-top: 40px;
                        }
                        
                    }
                    
                    .item-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -40px;
                        width: 50px;
                        height: 50px;
                    }
        
                }
                
            }
        
        }
        
        .conclusion-section {
        
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #F9F9F9;
        
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'lg');
                height:  350px ;
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 20px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.14px;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 62px;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 40px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height:  350px ;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
        
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1; 
                        top: 50px;
                        left: 0px;
                    }
        
                    .circle-decoration:nth-child(2) {
                        width:  50px;
                        height: 50px;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                    }
        
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        width: 450px ;
                        height:  350px ;
                    }
                }
            }
        
        }
        
    }
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .ai-operation{

    .introduction-section {

        display: flex;
        width: 100%;
        padding: 64px 20px;
        background-color: map.get(theme.$colors, "white");

        .section-container {
            width: 100%;
            max-width: map-get(theme.$brakpoint, 'sm');
            height: max-content;
            display: flex;
            flex-direction: row;
            margin: auto;

            .container-content {
                margin-left: 0px;
                padding-left: 0px;
                min-width: auto;

                .label {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }

                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgb(90, 83, 107);

                    span {
                        color: rgb(243, 104, 74);
                    }
                }

                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.6);
                }

                .container-image {
                    margin-left: auto;
                    width: 100%;
                    display: flex;

                    .image-container {
                        height: 237px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 100%;
                        margin-left: auto;
                    }

                    .circle-decoration:nth-child(1) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 150px;
                        right: -35px;
                        display: none;
                    }

                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        bottom: 70px;
                        left: -15px;
                        display: none;
                    }

                    .display-image {
                        width: 100%;
                        height:  100% ;
                        position: relative;
                        margin-left: 0px;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    }
                }
            }

            .container-image {
                display: none;
            }
        }

    }

    .outcome-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 50px 20px 50px 20px;
    
        .section-container {
            max-width: map-get(theme.$brakpoint, 'sm');
            margin: auto; 
            width: 100%;
    
            .container-head {
                display: flex;
                flex-direction: column;
                max-width: 1204px;
                margin: auto;

                .title {
                    color: black;
                    font-size: 18px;
                    font-family: Raleway;
                    font-weight: 400;
                    overflow-wrap: break-word;
                    text-align: center;
                }
                
                .subtitle {
                    font-family: Raleway;
                    font-weight: 500;
                    font-size: 30px;
                    text-align: center;
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .subtitle:nth-child(2){
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                }
    
            }
            
            .container-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0px;
                
                .content-item {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: left;
                    line-height: 27px;
                    width: 100%;
                    height: fit-content;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 0px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: map.get(theme.$colors, "white");
                    border-radius: 6px;
                    padding: 20px;
                    box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
    
                    .title {
                        font-family: Raleway; font-weight: 600; font-size: 20px; text-align: left; color: rgb(58, 49, 76); padding-top: 40px;
                    }

                }
                
                .item-icon {
                    margin-left: 15px;
                    position: absolute;
                    margin-top: -40px;
                    width: 50px;
                    height: 50px;
                }
    
            }
            
        }
    
    }

    .conclusion-section{
    
        display: flex;
        width: 100%;
        padding: 64px 20px;
        background-color: map.get(theme.$colors, "white");

        .section-container {
            width: 100%;
            max-width: map-get(theme.$brakpoint, 'sm');
            height: max-content;
            display: flex;
            flex-direction: row;
            margin: auto;

            .container-content {
                margin-left: 0px;
                margin-right: 0px;
                padding-right: 0px;
                max-width: 100%;
                width: 100%;

                .label {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.16em;
                    text-align: left;

                }

                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgb(90, 83, 107);

                    span {
                        color: rgb(243, 104, 74);
                    }
                }

                .description {
                    font-family: Raleway;
                    width: 100%;
                    font-weight: 500;
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.6);
                }

                .container-image {
                    width: 100%;
                    height: fit-content;
                    display: flex;

                    .image-container {
                        height: 237px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        padding-right: 20px;
                        width: 100%;
                        margin-right: auto;
                    }

                    .circle-decoration:nth-child(1) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 150px;
                        right: -35px;
                        display: none;
                    }

                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        bottom: 70px;
                        left: -15px;
                        display: none;
                    }

                    .display-image {
                        position: relative;
                        margin-left: 0px;
                        clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                        height: 100%;
                        width: 100%;
                        // width: calc(100vw - 60px);
                    }
                }
            }

            .container-image {
                display: none;
            }
        }

    }
    
    }

}