.global-managed-voice-section{
    width: 100%;

    .container{

        @include xxl {
            max-width: 100% ;
            background-color: aquamarine;
        }
        
        .container-body{
            display: flex;
            flex-direction: row;

            .container-body-title-section{
                width: 100%;

                .container-body-title-section{
                    max-width: 560px;
                }

            }

            .container-body-img{
                max-width: 750px;
                min-width: 650px;
            }
        }
       

        .service-industry-card{
            margin-left: 140px;
        }
    }
}