.life-at-freston-word-from-people{
    background-color: #F9F6F5;
}

.life-at-freston-section{
    width: 100%;
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
 
    background: linear-gradient(180deg, #FFFFFF 70%, #F9F6F5 30%);

    .section-container{
      display: flex;
      height: fit-content;
      flex-direction: row;
      margin: auto;
      max-width : 1600px ;
      width: 100%;

      .inner-container{
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 100%;
        max-width: 1210px;

        .mobile-hide{
          width: 50%;
        }

        .mobile-visible{
          width: 50%;
          margin-left: -40px;
        }

        .navigation-container{
          padding-top: 180px;
          display: flex;
          flex-direction: row;
          .navigation-arrow{
            width: 50px;
            height: 50px;
          }
        }

        .gallary-focus-item{
          border-radius: 6px;
          height: 322px;
          margin-top: 10px;
          width: 520px;
        }

        .gallery-focus-item-date{
          color: #5a536b;
          display: flex;
          font-family: Raleway;
          font-size: 16px;
          line-height: 150%;
        }

        .gallery-focus-item-label{
          color: #3a314c;
          display: flex;
          font-family: Raleway;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }

        .gallery-title{
          color: #363636;
          font-family: Raleway;
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          text-align: left;
        }

        .gallery-text{
          color: #7e7e7e;
          display: flex;
          font-family: Raleway;
          font-size: 20px;
          padding-right: 100px;
          text-align: left;
        }

        .gallery-item-container{
          display: flex;
          position: absolute;
          flex-direction: row;
          height: 345px;
          width: 100%;
          padding-top: 50px;

          .gallery-item{
            height: 254px;
            width: 292px;
           
            img{
              border-radius: 6px;
            }
          }

        }
      

        .gallery-social{
          display: flex;
          flex-direction: row;

          li{
            margin-right: 10px;
          }

        }
      }

    }

    &:nth-child(3){

     .gallery-item-container{
        padding-top: 150px !important;
        background-color: #3a314c;
     }

      background: white;

    }

}

.words-from-people-section {
  background-color: #F9F6F5;
  @include md{
    padding: 0px 0px;
  }
}

.life-at-freston{

    .main-section{
        display: flex;
        align-items: center;
        min-height: 100vh;
    
        div{
            margin: auto;
            width: fit-content;
            justify-content: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .button-container{
              display: flex;
              flex-direction: row;
              margin: auto;
              transform: translateX(20px);
              margin-top: 20px;
            }
          }
        
        .section-title{
            margin: auto;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
    
            .case-study{
              font-family: "Raleway";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.14px;
              transform: translateY(40px);
            }
          }
    
        .sub-title{
            height: 62px;
            margin-top: 16px;
            font-family: 'Raleway';
            font-size: 24px;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #EEEEEE;
          }
    
    }
}

.gallary{
    background: linear-gradient(180deg, #FFFFFF 70%, #F9F6F5 30%);
}

@media only screen and (max-width: map-get($brakpoint, 'md')){

  .life-at-freston-section{
    width: 100%;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF 70%, #F9F6F5 30%);
    height: fit-content;

    .section-container{
      display: flex;
      height: fit-content;
      flex-direction: row;
      // max-width : 1600px ;
      width: 100%;
      height: fit-content;
      margin: auto;
  

      .inner-container{
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 100%;
        height: 100%;


        .mobile-hide{
          display: none;
        }

        .mobile-visible{
          width: 100%;
          margin: 0;
          display: flex;
          flex-direction: column;
          position: relative;
        }

        .navigation-container{
          padding-top: 180px;
          display: flex;
          flex-direction: row;
          .navigation-arrow{
            width: 50px;
            height: 50px;
          }
        }

        .gallary-focus-item{
          border-radius: 6px;
          height: 322px;
          margin-top: 10px;
          width: 440px;
        }

        .gallery-focus-item-date{
          color: #5a536b;
          display: flex;
          font-family: Raleway;
          font-size: 16px;
          line-height: 150%;
        }

        .gallery-focus-item-label{
          color: #3a314c;
          display: flex;
          font-family: Raleway;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }

        .gallery-title{
          color: #3a314c;
          font-family: Raleway;
          font-size: 26px;
          font-weight: 500;
          line-height: 130%;
          span{
            color: #f3684a;;
          }
        }

        .gallery-text{
          align-items: center;
          color: #7e7e7e;
          display: flex;
          font-family: Raleway;
          font-size: 16px;
          line-height: 150%;
          padding-right: 0;
          text-align: left;
        }

        .gallery-item-container{
          position: relative;
          display: flex;
          height: -moz-fit-content;
          height: fit-content;
          order: 3;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          padding: 10px;
          padding-top: 40px;

          .gallery-item{
            border-radius: 6px;
            margin-top: auto;
            margin-right: 20px;
            min-width: 250px;
            display: flex;
            flex-direction: column;
          }

        }
      

        .gallery-social{
          display: flex;
          flex-direction: row;

          li{
            margin-right: 10px;
          }

        }
      }

    }

    &:nth-child(3){

     .gallery-item-container{
      padding-top: 150px !important;
     }

      background: white;

    }

  }

}
  