
@use "sass:map";

.blog-container {
    display: flex;
    flex-direction: column;

    .blog-head{
        display: flex;
        flex-direction: row;
        padding-bottom: 40px;

        .label{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 0px;
            font-family: Raleway;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.16em;
            text-align: left;
        }

        div{
            margin-left: auto;
        }


    }

    .blog-body {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;

        .blog-item {
                display: flex;
                flex-direction: row;
                width: 50%;
                height: 100%;

            .blog-display-pic {
                    border-radius: 6px;
                    width: 280px !important;
                    height: 280px !important;
                    object-fit: cover;
                }

            .blog-author {
                    font-family: 'Raleway';
                    font-style: normal;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                    color: #000000;
                }
                
            .blog-titile {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 130%;
                display: flex;
                align-items: center;
                color: #000000;
            }
                
            .blog-description {
                font-family: 'Raleway';
                font-size: 18px;
                line-height: 150%;
                display: flex;
                align-items: center;
                color: #5A536B;
            }

            div {
                    display: flex;
                    margin-left: 20px;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                }

            }
        
    }

  }
