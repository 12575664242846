.teams-cover-section {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
  background-color: #fbfbfb;
  .container {
    margin: auto;
    transform: translateY(15px);
  }
  .frame-bottom {
    margin-top: -40px;
    padding-bottom: 40px;
  }
}

.teams-cover-section .title,
.sub-title {
  margin: auto;
  justify-content: center;
  text-align: center;
}

.teams-body-section {
  padding: 100px 150px;
  background-color: #fbfbfb;
  .sub-title {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.14px;
    letter-spacing: 0.16em;
    text-align: left;
  }

  .title {
    //styleName: H2/light;
    font-family: Raleway;
    font-size: 50px;
    font-weight: 300;
    line-height: 65px;
    text-align: left;
    span {
      font-family: Raleway;
      font-size: 50px;
      font-weight: 500;
      line-height: 65px;
      text-align: left;
    }

    .color {
      color: #f3684a;
    }
  }

  .title-2 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 300;
    line-height: 65px;
    text-align: left;
    span {
      font-family: Raleway;
      font-size: 50px;
      font-weight: 400;
      line-height: 65px;
      text-align: left;
    }

    .color {
      color: #f3684a;
    }
  }


  .team-container {
    margin-top: 54px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 50px 80px;

    .grid-item {
      display: flex;
      position: relative;
      text-align: center;
      font-size: 30px;
      width: 356px;
      min-height: 219px;

      .dummy-photo{
        background-color: rgba(220, 255, 163, 1);
        color: rgba(142, 166, 103, 1);
        font-family: Raleway;
        font-size: 40px;
        font-weight: 600;
        line-height: 52px;
        text-align: left;
      }

      .photo {
        z-index: 1;
        position: absolute;
        display: flex;
        width: 100px;
        height: 100px;
        left: 24px;
        gap: 0px;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 0px 8px white;
        -moz-box-shadow: 0px 0px 0px 8px white;
        box-shadow: 0px 0px 0px 8px white;
        object-fit: fill;
      }

      .card {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        background-color: white;
        width: 100%;
        max-height: 185px;
        top: 39px;
        border-radius: 25px;
        border: 1px;
        padding: 25px;

        -webkit-box-shadow: 0px -10px 0px 0px #f4f4f4;
        -moz-box-shadow: 0px -10px 0px 0px #f4f4f4;
        box-shadow: 0px -10px 0px 0px #f4f4f4;

        .card-contaner {
          margin-top: 35px;
          height: 308px;
          display: flex;
          flex-direction: column;

          .name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .name {
              font-family: Raleway;
              font-size: 24px;
              font-weight: 500;
              line-height: 31.2px;
              text-align: left;
              padding-bottom: 4px;
            }
            .linkedin-icon-container{
                display: flex;
                flex-direction: column;
                height: 100%;
            }
          }

          .position-location-container{
            display: flex;
            margin-top: auto;
            flex-direction: column;

            .position {
                font-family: Raleway;
                font-size: 18px;
                font-weight: 500;
                line-height: 23.4px;
                text-align: left;
                padding-bottom: 4px;
              }
    
              .location-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: left;
                .location {
                  font-family: Raleway;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18.2px;
                  text-align: left;
                }
              }

          }


        }
      }
      
    }

  }
}


@media only screen and (max-width: map-get($brakpoint, 'xl')) {
    .teams-cover-section {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        width: 100%;
        background-color: #fbfbfb;
        .container {
          margin: auto;
          transform: translateY(15px);
        }
        .frame-bottom {
          margin-top: -40px;
          padding-bottom: 40px;
        }
      }
      
      .teams-cover-section .title,
      .sub-title {
        margin: auto;
        justify-content: center;
        text-align: center;
      }
      
      .teams-body-section {
        padding: 80px 100px;
        background-color: #fbfbfb;
        .sub-title {
          font-family: Raleway;
          font-size: 18px;
          font-weight: 400;
          line-height: 22.14px;
          letter-spacing: 0.16em;
          text-align: left;
        }
      
        .title {
          //styleName: H2/light;
          font-family: Raleway;
          font-size: 50px;
          font-weight: 300;
          line-height: 65px;
          text-align: left;
          span {
            font-family: Raleway;
            font-size: 50px;
            font-weight: 500;
            line-height: 65px;
            text-align: left;
          }
      
          .color {
            color: #f3684a;
          }
        }
      
        .title-2 {
          font-family: Raleway;
          font-size: 50px;
          font-weight: 300;
          line-height: 65px;
          text-align: left;
          span {
            font-family: Raleway;
            font-size: 50px;
            font-weight: 400;
            line-height: 65px;
            text-align: left;
          }
      
          .color {
            color: #f3684a;
          }
        }
      
      
        .team-container {
          margin-top: 54px;
          display: grid;
          grid-template-columns: auto auto;
          grid-template-rows: auto auto;
          grid-gap: 50px 80px;
      
          .grid-item {
            display: flex;
            position: relative;
            text-align: center;
            font-size: 30px;
            width: 356px;
            min-height: 219px;
      
            .dummy-photo{
              background-color: rgba(220, 255, 163, 1);
              color: rgba(142, 166, 103, 1);
              font-family: Raleway;
              font-size: 40px;
              font-weight: 600;
              line-height: 52px;
              text-align: left;
            }
      
            .photo {
              z-index: 1;
              position: absolute;
              display: flex;
              width: 100px;
              height: 100px;
              left: 24px;
              gap: 0px;
              border-radius: 50%;
              -webkit-box-shadow: 0px 0px 0px 8px white;
              -moz-box-shadow: 0px 0px 0px 8px white;
              box-shadow: 0px 0px 0px 8px white;
              object-fit: fill;
            }
      
            .card {
              display: flex;
              flex-direction: column;
              margin-top: 20px;
              background-color: white;
              width: 100%;
              max-height: 185px;
              top: 39px;
              border-radius: 25px;
              border: 1px;
              padding: 25px;
      
              -webkit-box-shadow: 0px -10px 0px 0px #f4f4f4;
              -moz-box-shadow: 0px -10px 0px 0px #f4f4f4;
              box-shadow: 0px -10px 0px 0px #f4f4f4;
      
              .card-contaner {
                margin-top: 35px;
                height: 308px;
                display: flex;
                flex-direction: column;
      
                .name-container {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  .name {
                    font-family: Raleway;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 31.2px;
                    text-align: left;
                    padding-bottom: 4px;
                  }
                  .linkedin-icon-container{
                      display: flex;
                      flex-direction: column;
                      height: 100%;
                  }
                }
      
                .position-location-container{
                  display: flex;
                  margin-top: auto;
                  flex-direction: column;
      
                  .position {
                      font-family: Raleway;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 23.4px;
                      text-align: left;
                      padding-bottom: 4px;
                    }
          
                    .location-container {
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: left;
                      .location {
                        font-family: Raleway;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18.2px;
                        text-align: left;
                      }
                    }
      
                }
      
      
              }
            }
            
          }
      
        }
      }
}


@media only screen and (max-width: map-get($brakpoint, 'md')){


    .teams-cover-section {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        width: 100%;
        background-color: #fbfbfb;
        .container {
          margin: auto;
          display: flex;
          flex-direction: column;
          width: 80%
        }
        .frame-bottom {
          margin-top: -40px;
          padding-bottom: 0px;
        }
      }
      
      .teams-cover-section .title,
      .sub-title {
        margin: auto;
        justify-content: center;
        text-align: center;
      }
      
      .teams-body-section {
        padding: 80px 20px;
        background-color: #fbfbfb;

        .sub-title {
          font-family: Raleway;
          font-size: 14px;
          font-weight: 400;
          line-height: 18.14px;
          letter-spacing: 0.14em;
          text-align: left;
        }
      
        .title {
          font-family: Raleway;
          font-size: 30px;
          font-weight: 300;
          line-height: 45px;
          text-align: left;
          span {
            font-family: Raleway;
            font-size: 30px;
            font-weight: 500;
            line-height: 45px;
            text-align: left;
          }
      
          .color {
            color: #f3684a;
          }
        }
      
        .title-2 {
            font-family: Raleway;
            font-size: 30px;
            font-weight: 300;
            line-height: 45px;
            text-align: left;
            span {
              font-family: Raleway;
              font-size: 30px;
              font-weight: 500;
              line-height: 45px;
              text-align: left;
            }
        
            .color {
              color: #f3684a;
            }
        }
      
        .team-container {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
      
          .grid-item {
            display: flex;
            position: relative;
            margin-top: 15px;
            text-align: center;
            font-size: 30px;
            width: 100%;
            height: fit-content;
            min-height: 190px;

            .dummy-photo{
                background-color: rgba(220, 255, 163, 1);
                color: rgba(142, 166, 103, 1);
                font-family: Raleway;
                font-size: 30px;
                font-weight: 600;
                line-height: 42px;
                text-align: left;
              }
      
            .photo {
              z-index: 1;
              position: absolute;
              display: flex;
              width: 80px;
              height: 80px;
              left: 20px;
              gap: 0px;
              border-radius: 50%;
              -webkit-box-shadow: 0px 0px 0px 8px white;
              -moz-box-shadow: 0px 0px 0px 8px white;
              box-shadow: 0px 0px 0px 8px white;
            }
      
            .card {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              background-color: white;
              width: 100%;
              height: 180px;
              top: 39px;
              gap: 0px;
              border-radius: 25px;
              border: 1px;
              padding: 25px;
      
              -webkit-box-shadow: 0px -10px 0px 0px #f4f4f4;
              -moz-box-shadow: 0px -10px 0px 0px #f4f4f4;
              box-shadow: 0px -10px 0px 0px #f4f4f4;
      
              .card-contaner {
                margin-top: 35px;
                height: 308px;
      
                .name-container {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  .name {
                    font-family: Raleway;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 25.2px;
                    text-align: left;
                    padding-bottom: 4px;
                  }
                  .linkedin-icon{
                    width: 25px;
                    height: 25px;
                  }
                }

                .position-location-container{
                    display: flex;
                    margin-top: auto;
                    flex-direction: column;

                    .position {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.4px;
                        text-align: left;
                        padding-bottom: 4px;
                      }
            
                      .location-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        .location {
                          font-family: Raleway;
                          font-size: 12px;
                          font-weight: 400;
                          line-height: 16.2px;
                          text-align: left;
                        }
                      }
                }
      

              }
            }
            
          }
      
        }
      }


}