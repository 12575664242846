@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('../css/extra.css');
@import url('../css/mobile-media.css');

/* html{
    overflow-x: hidden;
} */

:root {
    --xs: #1e90ff;
    --xs: 0;
    --sm: 420px;
    --md: 960px;
    --lg: 1200px;
    --xl: 1366px;
    --xxl: 1520px;
  }

/* .last-point{
    top:255px;
    left:350px;
    align-items: center;
    background-color: #998585;
    border: 2px solid #E6E6E6;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    transition: all 0.3s ease-in;
    width: 40px;

}

.last-point::before{
    background-color: #E6E6E6;
    border-radius: 50%;
    content: "";
    height: 5px;
    width: 5px;
}


.last-point::after {
    background-color: #F3684A;
    content: "";
    height: 3px;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 160px;
}

.last-point:hover {
    background-color: #F3684A;
    border-color: #F3684A;
    transition: all 0.3s ease-in;
}


.last-point-address{
    width: 300px;
    width: 300px;
    position: absolute;
    background-color: #2d201d;
} */