@use '../../../assets/sass/others/variables' as theme;

@use "sass:map";

.global-managed-service{

    .overview-section {
        display: flex;
        padding: 50px 0px;
        background-color: map.get(theme.$colors, "white");
    
    
        .section-container {
            display: flex;
            flex-direction: row;
            margin: auto;
            max-width: 1600px;
            width: 100%;
            margin-left: 130px;
            padding: 15px;
    
            div {
                width: 100%;
                margin-left: 0px;
                padding: 0px;
    
                .title {
                    font-family: Raleway;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 3px;
                    text-align: left;
                    color: rgba(28, 28, 28, 0.7);
                }
    
                .sub-title {
                    font-family: Raleway;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                }
            }
    
        }
    
    }
    
    .introduction-section {
    
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                    right: 0px;
                    transform: scale(-1);

                }
    
                .circle-decoration:nth-child(2) {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    left: -15px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    height: auto;
                    width: 750px;
                }
            }
        }
    
    }
    
    .challenges-section {
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 200px;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            width: 100%;
            margin: auto;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            position: relative;
    
            .container-image {
                height: 547px;
                display: flex;
                flex-direction: column;
                position: relative;
    
                .circle-decoration {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                }
    
                .display-image {
                    position: relative;
                    clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
    
            .container-content {
                margin-left: 20px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
            }
    
            .card-container {
                position: absolute;
                bottom: -150px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
    
                .card {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 400px;
                    height: 262px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    border: none;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -60px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Raleway;
                        font-weight: 600;
                        font-size: 24px;
                        text-align: left;
                        color: rgb(58, 49, 76);
                        padding-top: 40px;
                    }
                }
    
                // .card:nth-child(1){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(2){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(3){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
    
    
            }
        }

    }
    
    .gears-of-project-section {
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: rgb(249, 249, 249);
        display: flex;
        flex-direction: column;
    
        .section-container {
            margin: auto;
            display: flex;
            flex-direction: column;
    
            .container-head {
                margin: auto;
    
                .title {
                    font-size: 50px;
                    font-weight: 300;
                    line-height: 65px;
                    color: rgb(243, 104, 74);
                    text-align: center;
    
                    span {
                        color: black;
                        font-weight: 500;
                    }
                }
    
                .description {
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 27px;
                    color: rgba(0, 0, 0, 0.6);
                    text-align: center;
                }
            }
    
            .container-content {
                display: flex;
                flex-direction: row;
                width: 1200px;
                margin: 100px auto auto;
    
                .content-item {
                    width: 395px;
                    height: 395px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    margin-left: 20px;
                    box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 19px 0px;
    
                    .content-icon {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 40px;
                    }
    
                    .title {
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 31.2px;
                        color: rgb(67, 67, 67);
                        text-align: left;
                    }
    
                    .description {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        color: rgb(126, 126, 126);
                        text-align: left;
                    }
                }
            }
        }
    }
    
    .implementation-process-section {
        display: flex; width: 100%; padding-top: 100px; padding-bottom: 100px;
    
        .section-container{
            
            display: flex; flex-direction: row; max-width: 1600px; margin: auto; width: 100%;
    
            .sticky-top{
                width: 755.5px; font-family: Raleway; translate: none; rotate: none; scale: none; padding: 0px 0px 0px 140px; inset: 0px auto auto 0px; margin: 0px; max-width: 755.5px; max-height: 3357.59px; height: 3357.59px; transform: translate(0px, 0px);
    
                .title{
                    font-size: 50px; font-weight: 300; line-height: 65px; color: rgb(58, 49, 76);
                    span{
                        color: rgb(243, 104, 74);
                    }
                }
                .item-container{
                    margin-top: 30px;
 
                    .content-item{
                        font-weight: 600; font-size: 32px; line-height: 41.6px; display: flex; flex-direction: row; opacity: 1; padding-left: 20px; border-left: 5px solid rgb(243, 104, 74);
                        span{
                            padding-right: 20px;
                        }
                    }
                }
            }
    
            .container-content{
                width: 50%; display: flex;
                .item-container-scroll{
                    margin: auto;

                    .title{
                        display: none;
                    }
                    
                    .scroll-content-item{
                        display: flex; flex-direction: column; width: 600px; padding-bottom: 100px; padding-top: 100px;
                        
                        .content-item-mobile-title{
                            display: none;
                        }

                        .display-image{
                            width: 380px; height: 278px; margin: auto; padding-bottom: 30px;
                        }

                        .title{
                            background-color: aqua;
                            font-size: 50px; font-weight: 300; line-height: 65px; color: rgb(243, 104, 74);
                            span{
                                color: rgb(90, 83, 107);
                            }
                        }
                        .description{
                            font-family: Raleway;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 30px;
                            padding-right: 20px;
                            letter-spacing: 0em;
                            color: rgba(90, 83, 107, 1);
                        }
                    }
                }
            }
    
        }
    }
    
    .outcome-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgb(249, 249, 249);
        padding-bottom: 100px;
        padding-top: 100px;
    
        .section-container {
            max-width: 1600px; 
            margin: auto; 
            width: 100%;
    
            .container-head {
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin: auto;
    
                .title {
                    color: black;
                    font-size: 18px;
                    font-family: Raleway;
                    font-weight: 400;
                    overflow-wrap: break-word;
                    text-align: center;
                }
                
                .subtitle {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 50px;
                    text-align: center;
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .subtitle:nth-child(3){
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                }
    
            }
            
            .container-content {
                display: flex;
                flex-flow: wrap;
                width: 1070px;
                margin: 100px auto auto;
    
                .content-item {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 500px;
                    height: 262px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: map.get(theme.$colors, "white");
                    border-radius: 6px;
                    padding: 20px;
                    box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
    
                    .title {
                        font-family: Raleway; font-weight: 600; font-size: 24px; text-align: left; color: rgb(58, 49, 76); padding-top: 60px;
                    }
                }
                
                .item-icon {
                    margin-left: 15px;
                    position: absolute;
                    margin-top: -60px;
                    width: 70px;
                    height: 70px;
                }
    
            }
            
        }
    
    }
    
    .conclusion-section {
    
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-right: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1; 
                    top: 70px;
                    left: 0px;
                }
    
                .circle-decoration:nth-child(2) {
                    width:  70px;
                    height: 70px;
                    position: absolute;
                    bottom: 60px;
                    right: 45px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
        }
    
    }
}

.operator-connect{
    
    .overview-section {
        display: flex;
        padding: 50px 0px;
        background-color: map.get(theme.$colors, "white");
    
    
        .section-container {
            display: flex;
            flex-direction: row;
            margin: auto;
            max-width: 1600px;
            width: 100%;
            margin-left: 130px;
            padding: 15px;
    
            div {
                width: 100%;
                margin-left: 0px;
                padding: 0px;
    
                .title {
                    font-family: Raleway;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 3px;
                    text-align: left;
                    color: rgba(28, 28, 28, 0.7);
                }
    
                .sub-title {
                    font-family: Raleway;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                }
            }
    
        }
    
    }
    
    .introduction-section {
    
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 100px;
                    right: 0px;
                    transform: scale(-1);
                }
    
                .circle-decoration:nth-child(2) {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    left: -15px;
                
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    height: auto;
                    width: 750px;
                }
            }
        }
    
    }
    
    .challenges-section {
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 200px;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            width: 100%;
            margin: auto;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            position: relative;
    
            .container-image {
                height: 547px;
                display: flex;
                flex-direction: column;
                position: relative;
    
                .circle-decoration {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                }
    
                .display-image {
                    position: relative;
                    clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
    
            .container-content {
                margin-left: 20px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
            }
    
            .card-container {
                position: absolute;
                bottom: -330px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
    
                .card {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 480px;
                    height: 240px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 50px;
                    margin-top: 30px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    border: none;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -60px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Raleway;
                        font-weight: 600;
                        font-size: 24px;
                        text-align: left;
                        color: rgb(58, 49, 76);
                        padding-top: 40px;
                    }
                }
    
                // .card:nth-child(1){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(2){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(3){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
    
    
            }
        }
    }
    
    .outcome-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgb(249, 249, 249);
        padding-bottom: 100px;
        padding-top: 200px;
    
        .section-container {
            max-width: 1600px; 
            margin: auto; 
            width: 100%;
    
            .container-head {
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin: auto;
    
                .title {
                    color: black;
                    font-size: 18px;
                    font-family: Raleway;
                    font-weight: 400;
                    overflow-wrap: break-word;
                    text-align: center;
                }
                
                .subtitle {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 50px;
                    text-align: center;
                    color:black;

                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .subtitle:nth-child(3){
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                }

                .subtitle:nth-child(4){
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                }
    
            }
            
            .container-content {
                display: flex;
                flex-flow: wrap;
                width: 1070px;
                margin: 100px auto auto;
    
                .content-item {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 500px;
                    height: 262px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: map.get(theme.$colors, "white");
                    border-radius: 6px;
                    padding: 20px;
                    box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
    
                    .title {
                        font-family: Raleway; font-weight: 600; font-size: 24px; text-align: left; color: rgb(58, 49, 76); padding-top: 60px;
                    }
                }
                
                .item-icon {
                    margin-left: 15px;
                    position: absolute;
                    margin-top: -60px;
                    width: 70px;
                    height: 70px;
                }
    
            }
            
        }
    
    }
    
    .conclusion-section {
    
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                padding-right: 30px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-right: auto;
    
                }
    
                .circle-decoration:nth-child(1) {
    
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1; 
                    top: 70px;
                    left: 0px;
                }
    
                .circle-decoration:nth-child(2) {
                    width:  70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    right: 35px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
        }
    
    }
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .global-managed-service{

        .overview-section {
            display: flex;
            padding: 40px 0px;
            background-color: map.get(theme.$colors, "white");
        
            .section-container {
                display: flex;
                flex-direction: row;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'lg');
                width: 100%;
                padding: 10px;
        
                div {
                    width: 100%;
                    margin-left: 0px;
                    padding: 0px;
        
                    .title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 2px;
                        text-align: left;
                        color: rgba(28, 28, 28, 0.7);
                    }
        
                    .sub-title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: left;
                    }
                }
        
            }
        
        }
        
        .introduction-section {
        
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #F9F9F9;
        
            .section-container {
                width: 100%;
                height:  350px;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    min-width: 440px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 130%;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1;
                        top: 50px;
                        right: 0px;
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
        
                    .circle-decoration:nth-child(2) {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        bottom: 20px;
                        left: -15px;
                    }
        
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        width: 450px ;
                        height:  350px ;
                    }
                }
            }
        
        }
        
        .challenges-section {
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 200px;
            background-color: rgb(249, 249, 249);
        
            .section-container {
                width: 100%;
                height:  350px;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                position: relative;
        
    
                .container-image {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
        
                    .circle-decoration {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1;
                        top: 50px;
                    }
        
                    .display-image {
                        width: 450px ;
                        height:  350px ;
                        position: relative;
                        clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    }
                }
        
                .container-content {
                    margin-left: 20px;
                    min-width: 540px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.14px;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 62px;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
                }
        
                .card-container {

                    position: absolute;
                    bottom: -180px;
                    display: flex;
                    flex-direction: row;
        
                    .card {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: left;
                        line-height: 25px;
                        width: 300px;
                        height: 230px;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 15px;
                        margin-bottom: 30px;
                        margin-top: 30px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        outline: none;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 10px;
                            position: absolute;
                            margin-top: -45px;
                            width: 50px;
                            height: 50px;
                        }
        
                        .title {
                            font-family: Raleway;
                            font-weight: 600;
                            font-size: 22px;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 25px;
                        }
                    }
                
                }
            }
        }
    
        
        .outcome-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgb(249, 249, 249);
            padding-bottom: 100px;
            padding-top: 100px;
        
            .section-container {
                max-width: map-get(theme.$brakpoint, 'lg');
                margin: auto; 
                width: 100%;
        
                .container-head {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    margin: auto;
        
                    .title {
                        color: black;
                        font-size: 16px;
                        font-family: Raleway;
                        font-weight: 400;
                        overflow-wrap: break-word;
                        text-align: center;
                    }
                    
                    .subtitle {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 40px;
                        text-align: center;
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .subtitle:nth-child(3){
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: center;
                    }
        
                }
                
                .container-content {
                    display: flex;
                    flex-flow: wrap;
                    width: 100%;
                    margin: 100px auto auto;
                    justify-content: center;
        
                    .content-item {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: left;
                        line-height: 25px;
                        width: 400px;
                        height: 252px;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 20px;
                        margin-bottom: 30px;
                        margin-top: 20px;
                        background-color: map.get(theme.$colors, "white");
                        border-radius: 6px;
                        padding: 20px;
                        box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
        
                        .title {
                            font-family: Raleway; font-weight: 600; font-size: 22px; text-align: left; color: rgb(58, 49, 76); padding-top: 40px;
                        }
                    }
                    
                    .item-icon {
                        margin-left: 10px;
                        position: absolute;
                        margin-top: -40px;
                        width: 50px;
                        height: 50px;
                    }
        
                }
                
            }
        
        }
        
        .conclusion-section {
        
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #F9F9F9;
        
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'lg');
                height:  350px ;
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 20px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.14px;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 62px;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 40px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height:  350px ;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
        
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        z-index: 1; 
                        top: 50px;
                        left: 0px;
                    }
        
                    .circle-decoration:nth-child(2) {
                        width:  50px;
                        height: 50px;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                    }
        
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        width: 450px ;
                        height:  350px ;
                    }
                }
            }
        
        }
        
    }

}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .global-managed-service{

        .overview-section {
            display: flex;
            flex-direction: row;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
    
            .section-container {
                display: flex;
                flex-direction: column;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'sm');
                padding: 0px;
                width: 100%;
    
                div {
                    width: fit-content;
                    margin-left: 0px;
                    padding: 15px;
    
                    .title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21px;
                        letter-spacing: 3px;
                        text-align: left;
                        color: rgba(28, 28, 28, 0.7);
                    }
    
                    .sub-title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: left;
                    }
                }
    
            }
    
        }
    
        .introduction-section {
    
            display: flex;
            width: 100%;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;
    
                .container-content {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: 600px;

                    min-width: auto;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
    
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }
    
                    .container-image {
                        width: fit-content;
                        display: flex;
    
                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: 12px;
                        }
    
                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }
    
                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }
    
                        .display-image {
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
    
                .container-image {
                    display: none;
                }
            }
    
        }
    
        .challenges-section {
            display: flex;
            width: 100%;
            padding: 64px 20px;
    
            .section-container {
                width: 100%;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'sm');;
                height: max-content;
                display: flex;
                flex-direction: column;
                position: relative;
    
                .container-image {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    display: none;
    
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 70px;
                    }
    
                    .display-image {
                        position: relative;
                        clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                        height: 100%;
                        width: 750px;
                    }
                }
    
                .container-content {
                    margin-left: 0px;
                    max-width: 600px;
                    min-width: auto;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
                }
    
                .card-container {
                    position: relative;
                    bottom: -80px;
                    display: flex;
                    flex-direction: column;
    
                    .card {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
    
                        width: 100%;
                        height: 100%;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 0px;
                        margin-bottom: 60px;
                        margin-top: 0px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                        .card-icon {
                            margin-left: 5px;
                            position: absolute;
                            margin-top: -60px;
                            width: 70px;
                            height: 70px;
                            transform: scale(0.8);
                        }
    
                        .title {
                            font-family: Raleway;
                            font-size: 19px;
                            font-weight: 600;
                            line-height: 23px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 30px;
                        }
                    }
    
                    // .card:nth-child(1){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(2){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(3){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
    
                }
            }
        }
    
        .gears-of-project-section {
            width: 100%;
            padding : 64px 20px;
            background-color: rgb(249, 249, 249);
            display: flex;
            flex-direction: column;
    
            .section-container {
                margin: auto;
                display: flex;
                flex-direction: column;
                max-width: map-get(theme.$brakpoint, 'sm');
    
                .container-head {
                    margin: auto;
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 400;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(58, 49, 76, 1);
    
                        // font-size: 50px; font-weight: 300; line-height: 65px; color: rgb(243, 104, 74); text-align: center;
                        span {
                            color: rgba(243, 104, 74, 1);
                            font-weight: 400;
                        }
                    }
    
                    .description {
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 27px;
                        color: rgba(0, 0, 0, 0.6);
                        text-align: center;
                    }
                }
    
                .container-content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin: 100px auto auto;
    
                    .content-item {
                        width: 100%;
                        height: 100%;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        margin-bottom: 20px;
                        margin-left: 0px;
                        box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 19px 0px;
    
                        .content-icon {
                            width: 50px;
                            height: 50px;
                            margin-bottom: 40px;
                        }
    
                        .title {
                            font-family: Raleway;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 26px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgb(67, 67, 67);
                        }
    
                        .description {
                            font-family: Raleway;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgb(126, 126, 126);
                        }
                    }
                }
            }
        }
    
        .implementation-process-section {
            display: flex; width: 100%; padding-top: 64px 20px;
    
            .section-container{
                display: flex; flex-direction: row; max-width: map-get(theme.$brakpoint, 'sm'); margin: auto; width: 100%;
    
                .sticky-top{
                    display: none;
                    width: 100%; font-family: Raleway; translate: none; rotate: none; scale: none; padding: 0px 0px 0px 120px; inset: 0px auto auto 0px; margin: 0px; max-width: 755.5px; max-height: 3357.59px; height: 3357.59px; transform: translate(0px, 0px);
    
                    .title{
                        font-size: 50px; font-weight: 300; line-height: 65px; color: rgb(58, 49, 76);
                        span{
                            color: rgb(243, 104, 74);
                        }
                    }
                    .item-container{
                  
                        margin-top: 30px;
                        .content-item{
                            font-weight: 600; font-size: 32px; line-height: 41.6px; display: flex; flex-direction: row; opacity: 1; padding-left: 20px; border-left: 5px solid rgb(243, 104, 74);
                            span{
                                padding-right: 20px;
                            }
                        }
                    }
                }
    
                .container-content{
                    width: 100%; 
                    display: flex;
    
                    .item-container-scroll{
                        margin: auto;
                        padding: 0px 20px;
                        .title{
                            font-family: Raleway;
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 34px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(90, 83, 107, 1);
                            span{
                            color: rgba(243, 104, 74, 1);
                            }
                        }
                        .scroll-content-item{
                            display: flex; flex-direction: column; width: 100%; padding-bottom: 20px; padding-top: 20px;
                            .content-item-mobile-title{
                                font-family: Raleway;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 26px;
                                letter-spacing: 0em;
                                text-align: left;
                                padding-bottom: 20px;
                                display: flex; flex-direction: row; opacity: 1;
                                span{
                                    padding-right: 20px;
                                }
                            }
                            .display-image{
                                width: 280px;
                                height: 220px;
                                margin: auto; padding-bottom: 30px;
                            }
                            .title{
                                display: none;
                                span{
                                    color: rgb(90, 83, 107);
                                }
                            }
                            .description{
                                font-family: Raleway;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(90, 83, 107, 1);
                            }
                        }
                    }
                }
    
            }
        }
    
        .outcome-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgb(249, 249, 249);
            padding: 64px 20px;
    
            .section-container {
                display: flex; flex-direction: column; max-width: map-get(theme.$brakpoint, 'sm'); margin: auto; width: 100%;
    
                .container-head {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    margin-left: 0px;
    
    
                    .title {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;                           
                    }
                    
                    .subtitle {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
    
                    .subtitle:nth-child(3){
                        display: none;
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 14px;
                        text-align: left;
                    }
    
                }
                
                .container-content {
                    display: flex;
                    flex-flow: wrap;
                    flex-direction: column;
                    width: 100%;
                    margin: 40px auto auto;
    
                    .content-item {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        
                        width: fit-content;
                        height: 262px;
                        margin-left: 0px;
                        color: rgba(58, 49, 76, 0.8);
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';
    
                        .title {
                            font-family: Raleway;
                            font-size: 19px;
                            font-weight: 600;
                            line-height: 23px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 40px;
    
                        }
                    }
                    
                    .item-icon {
                        margin-left: 8px;
                        position: absolute;
                        margin-top: -55px;
                        transform: scale(0.8);
                    }
    
                }
                
            }
    
        }
    
        .conclusion-section{
    
            display: flex;
            width: 100%;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;
    
                .container-content {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: 600px;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
    
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }
    
                    .container-image {
                        width: 100%;
                        height: fit-content;
                        display: flex;
    
                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: auto;
                        }
    
                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }
    
                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }
    
                        .display-image {
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
    
                .container-image {
                    display: none;
                }
            }
    
        }
        
    }

    .operator-connect{
    
        .overview-section {
            display: flex;
            flex-direction: row;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
    
            .section-container {
                display: flex;
                flex-direction: column;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'sm');
                padding: 0px;
                width: 100%;
    
                div {
                    width: fit-content;
                    margin-left: 0px;
                    padding: 15px;
    
                    .title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21px;
                        letter-spacing: 3px;
                        text-align: left;
                        color: rgba(28, 28, 28, 0.7);
                    }
    
                    .sub-title {
                        font-family: Raleway;
                        width: 100%;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 21px;
                        text-align: left;
                    }
                }
    
            }
    
        }
        
        .introduction-section {
    
            display: flex;
            width: 100%;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;
    
                .container-content {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: 600px;

                    min-width: auto;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
    
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }
    
                    .container-image {
                        width: fit-content;
                        display: flex;
    
                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: 12px;
                        }
    
                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }
    
                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }
    
                        .display-image {
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
    
                .container-image {
                    display: none;
                }
            }
    
        }
        
        .challenges-section {
            display: flex;
            width: 100%;
            padding: 64px 20px;
    
            .section-container {
                width: 100%;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'sm');;
                height: max-content;
                display: flex;
                flex-direction: column;
                position: relative;
    
                .container-image {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    display: none;
    
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 70px;
                    }
    
                    .display-image {
                        position: relative;
                        clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                        height: 100%;
                        width: 750px;
                    }
                }
    
                .container-content {
                    margin-left: 0px;
                    max-width: 600px;
                    min-width: auto;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
                }
    
                .card-container {
                    position: relative;
                    bottom: -80px;
                    display: flex;
                    flex-direction: column;
    
                    .card {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
    
                        width: 100%;
                        height: 100%;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 0px;
                        margin-bottom: 60px;
                        margin-top: 0px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                        .card-icon {
                            margin-left: 5px;
                            position: absolute;
                            margin-top: -60px;
                            width: 70px;
                            height: 70px;
                            transform: scale(0.8);
                        }
    
                        .title {
                            font-family: Raleway;
                            font-size: 19px;
                            font-weight: 600;
                            line-height: 23px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 30px;
                        }
                    }
    
                    // .card:nth-child(1){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(2){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(3){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
    
                }
            }
        }
        
        .outcome-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgb(249, 249, 249);
            padding: 60px 20px;
        
            .section-container {
                max-width: 1600px; 
                margin: auto; 
                width: 100%;
        
                .container-head {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    margin: auto;
        
                    .title {
                        color: black;
                        font-size: 18px;
                        font-family: Raleway;
                        font-weight: 400;
                        overflow-wrap: break-word;
                        text-align: center;
                    }
                    
                    .subtitle {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 30px;
                        text-align: center;
                        color:black;
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .subtitle:nth-child(3){
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: center;
                    }
    
                    .subtitle:nth-child(4){
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: center;
                    }
        
                }
                
                .container-content {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // margin: 100px auto auto;
        
                    .content-item {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: left;
                        line-height: 27px;
                        width: fit-content;
                        height: fit-content;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 0px;
                        background-color: map.get(theme.$colors, "white");
                        border-radius: 6px;
                        padding: 20px;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .title {
                            font-family: Raleway; font-weight: 600; font-size: 24px; text-align: left; color: rgb(58, 49, 76); padding-top: 40px;
                        }
                    }
                    
                    .item-icon {
                        margin-left: 10px;
                        position: absolute;
                        margin-top: -50px;
                        width: 70px;
                        height: 70px;
                        transform: scale(0.8);
                    }
        
                }
                
            }
        
        }
        
        .conclusion-section{
    
            display: flex;
            width: 100%;
            padding: 64px 20px;
            background-color: map.get(theme.$colors, "white");
    
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;
    
                .container-content {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: 600px;
                    width: 100%;
    
                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
    
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }
    
                    .container-image {
                        width: 100%;
                        height: fit-content;
                        display: flex;
    
                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: auto;
                        }
    
                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }
    
                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }
    
                        .display-image {
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
    
                .container-image {
                    display: none;
                }
            }
    
        }
        
    }

}