// - Base styles and common styles ------------------


// html {
//     overflow-x: hidden;
// }

body {
    font-family: Raleway,sans-serif ;
    font-weight: 400;
    color: $text-one;
    background-color: white;

    &.fixed {
        overflow: hidden;
        padding-right: 15px;

        .top-header {
            padding-right: 15px;
        }
    }

    main{
        height: auto;
        z-index: 1;
    }
}

.mobile-display{
    display: none;
    @include md {
        display: flex;
    }
}

.large-display{
    display: flex;
    @include md {
        display: none;
    }
}

.container {
    max-width: 1210px;

    &.two {
        max-width: 1230px;
    }

    @include md {
        padding: 64px 20px;
    }
}

article {
    background-color: map-get($colors, 'white');
    position: relative;
    z-index: 1;
}

.show {
    clear: both;
}

.container{
    padding: 0px 150px !important;
    max-width: 1660px !important;

    @include md {
        padding: 60px 20px !important;
    }
}

// - More Button ------------------
// .more-button {
//     color: map-get($colors, 'white');
//     display: inline-flex;
//     height: 70px;
//     align-items: center;
//     font-size: 1.25rem;
//     font-weight: 600;
//     margin-top: 30px;
//     position: relative;
//     text-decoration: none;
//     text-transform: uppercase;

//     &:hover {
//         &::before {
//             animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
//             left: calc(100% - 42px);
//         }

//         .star-icon {
//             animation: starAnimation 0.9s ease-in-out infinite;
//             position: relative;
//         }
//     }

//     &::before {
//         background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
//         background-size: 400% 400%;
//         border-radius: 50px;
//         content: '';
//         display: inline-block;
//         height: 70px;
//         position: absolute;
//         width: 70px;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
//     }

//     span {
//         padding: 0 35px 0 25px;
//         position: relative;
//         z-index: 3;
//     }

//     .star-icon {
//         // animation: starAnimation 0.9s ease-in-out infinite;
//         position: relative;
//     }
// }

@keyframes starAnimation {
    0% {
        opacity: 0.5;
        transform: scale(0.5);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0.5;
        transform: scale(0.5);
    }
}

@keyframes iconAnimationIn {
    0% {
        width: 70px;
        left: 0;
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 70px;
        left: calc(100% - 42px);
    }
}

@keyframes iconAnimationOut {
    0% {
        width: 70px;
        left: calc(100% - 42px);
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 70px;
        left: 0;
    }
}

@keyframes backgroundAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

// - Content Title, Head & Text ------------------
.section-title {
    font-size: 3.125rem;
    font-weight: 300;

    b {
        font-weight: 500;
    }

    .section-text {
        margin-top: 20px;

        @include md {
            font-size: 18px;
        }

    }

    @include lg {
        font-size: 2.125rem;
    }

    @include md {
        font-size: 28px;
    }
}

.section-head {
    text-transform: uppercase;
    font-weight: 300;
    color: $text-two;
}

.section-text {
    font-size: 1.25rem;
    line-height: 1.7;

    @include md {
        font-size: 18px;
    }

    // @include lg {
    //     font-size: 1rem;
    // }
}

.section-align {
    display: flex;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;

    @include md {
        padding: 0px 0px;
        min-height: auto;
    }
}

// - Explore Button ------------------
.explore-button {
    color: $text-one;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    letter-spacing: 1px;
    transition: all 0.5s ease-in;

    &::after {
        content: '';
        height: 3px;
        width: 38px;
        display: inline-flex;
        background-color: $secondery;
        position: absolute;
        left: calc(100% + 10px);
        transition: all 0.3s ease-in;
    }

    &:hover {
        letter-spacing: 10px;
        transition: all 0.5s ease-out;

        &::after {
            left: 0px;
            width: 100%;
            transition: all 0.3s ease-out;
        }
    }
}

// - Wave Animation ------------------

.wave-animation {
    display: flex;
    align-items: center;

    .sound-wave {
        width: 250px;
        height: 130px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .bar {
            display: block;
            width: 2px;
            margin-right: 1px;
            height: 130px;
            background: rgba(map-get($colors, 'black'), 0.3);
            animation: sound 0ms -800ms linear infinite alternate;
            transition: height 5s;

            &:nth-child(1) {
                height: 2px;
                animation-duration: 474ms;
            }

            &:nth-child(2) {
                height: 10px;
                animation-duration: 433ms;
            }

            &:nth-child(3) {
                height: 18px;
                animation-duration: 407ms;
            }

            &:nth-child(4) {
                height: 26px;
                animation-duration: 458ms;
            }

            &:nth-child(5) {
                height: 30px;
                animation-duration: 400ms;
            }

            &:nth-child(6) {
                height: 32px;
                animation-duration: 427ms;
            }

            &:nth-child(7) {
                height: 34px;
                animation-duration: 441ms;
            }

            &:nth-child(8) {
                height: 36px;
                animation-duration: 419ms;
            }

            &:nth-child(9) {
                height: 40px;
                animation-duration: 487ms;
            }

            &:nth-child(10) {
                height: 46px;
                animation-duration: 442ms;
            }

            &:nth-child(11) {
                height: 2px;
                animation-duration: 474ms;
            }

            &:nth-child(12) {
                height: 10px;
                animation-duration: 433ms;
            }

            &:nth-child(13) {
                height: 18px;
                animation-duration: 407ms;
            }

            &:nth-child(14) {
                height: 26px;
                animation-duration: 458ms;
            }

            &:nth-child(15) {
                height: 30px;
                animation-duration: 400ms;
            }

            &:nth-child(16) {
                height: 32px;
                animation-duration: 427ms;
            }

            &:nth-child(17) {
                height: 34px;
                animation-duration: 441ms;
            }

            &:nth-child(18) {
                height: 36px;
                animation-duration: 419ms;
            }

            &:nth-child(19) {
                height: 40px;
                animation-duration: 487ms;
            }

            &:nth-child(20) {
                height: 46px;
                animation-duration: 442ms;
            }
        }
    }
}

  .pulsating-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 30px;
    
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color:#F3684A;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0 0 8px rgba(0,0,0,.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }
  
    @keyframes pulse-ring {
        0% {
        transform: scale(.33);
        }
        80%, 100% {
        opacity: 0;
        }
    }
    
    @keyframes pulse-dot {
        0% {
        transform: scale(.8);
        }
        50% {
        transform: scale(1);
        }
        100% {
        transform: scale(.8);
        }
    }

    @keyframes sound {
        0% {
            opacity: .35;
            height: 6px;
        }

        100% {
            opacity: 1;
            height: 110px;
        }
    }

    @keyframes soundClient {
        0% {
            opacity: .35;
            height: 6px;
        }

        100% {
            opacity: 1;
            height: 35px;
        }
    }