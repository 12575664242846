.word-from-people{
    background-color: inherit;
    margin: auto;
    padding: 100px 20px;


    .title {
        width: 618px;
        left: 140px;
        // padding-top: 100px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 130%;
        text-align: left;
        color: #3A314C;
        span{
            color: #F3684A;
        }
      }

    .large{
        display: flex;
    }
    .mobile{
        display: none;
    }

      .first{

        // padding-top: 80px;
        // padding-bottom: 80px;
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        .navigation-container {

            display: flex;
            flex-direction: row;
            justify-content: left;
            padding-bottom: 30px;

            .navigation-arrow {
                padding: 5px;
                height: 50px;
                height: 50px;
                cursor: pointer;
              }
  
          }

          .body{

            display: flex;
            flex-direction: row;
            height: 100%;

            .quotes {
                width: 90px;
                height: 70px;
                padding: 10px;
            }

            .people{

                display: flex; 
                flex-direction: column;
                justify-content: space-between;

                .people-words {

                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 130%;
                    text-align: left;
                    color: #3A314C;
                    font-style: italic;
                  }
                  
                  .people-name {
                    margin-top: 40px;
                    font-family: 'Raleway';
                    font-size: 24px;
                    line-height: 34px;
                    text-align: left;
                    color: #3A314C;

                    span {
                        font-family: 'Raleway';
                        font-size: 20px;
                        line-height: 34px;
                        display: flex;
                        align-items: left;
                        color: #5A536B;
                      }

                  }
            }
          }

      }

      .people-img {
        width: 350px;
        border-radius: 50%;
        height: 350px;
        margin: auto;
        object-fit: cover;
        object-position: center;
      }

      .second{
        height: 100%;
        display: flex;
      }
}


@media only screen and (max-width: 960px){
  .word-from-people{
    display: flex;
    flex-direction: column;
    background-color: inherit;
    margin: auto;
    height: fit-content;
    padding: 64px 20px;

    .title {
        width: 100%;
        font-size: 24px;
        padding: 10px;
        order: 1;

        span{
            color: #F3684A;
        }
    }

    .large{
        order: 2;
        display: none;
    }

    .mobile::-webkit-scrollbar {
      display: none;
    }

    .mobile{
        display: flex;
        height: fit-content;
        order: 3;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none; 
        padding: 10px;    
        padding-top: 40px;

        .inner-card{
          margin-top: auto;
          margin-right: 20px;
          min-width: 250px;
          display:flex ;
          flex-direction: column;
          background-color: white;
          box-shadow: 0px -1px 11px rgba(126, 126, 126, 0.2);
          -webkit-box-shadow: 0px -1px 11px rgba(126, 126, 126, 0.2);
          -moz-box-shadow: 0px -1px 11px rgba(126, 126, 126, 0.2);
          .inner-card-body{

            .inner-card-words{
              font-size: 16px;
                font-family: 'Raleway';
                font-style: italic;
                line-height: 150%;
            }
            .inner-card-author{
              display: flex;
              flex-direction: column;
              color: #000;
              font-size: 20px;
              font-family: 'Raleway';
              line-height: 100%;
              white-space: nowrap;
              text-align: left;
              justify-content: left;
              .span{
                color: #4D4D4D;
                margin: 0px;
                font-size: 16px;
                font-family: Raleway;
                line-height: 130%;
              }
            }
          }

        }

    }

    .first{

      // padding-top: 80px;
      // padding-bottom: 80px;
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      
      .navigation-container {

          display: flex;
          flex-direction: row;
          justify-content: left;
          padding-bottom: 30px;

          .navigation-arrow {
              padding: 5px;
              height: 50px;
              height: 50px;
            }

        }

        .body{

          display: flex;
          flex-direction: row;
          height: 100%;

          .quotes {
              width: 90px;
              height: 70px;
              padding: 10px;
          }

          .people{

              display: flex; 
              flex-direction: column;
              justify-content: space-between;
              height: 400px;

              .people-words {

                  font-family: 'Raleway';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 130%;
                  text-align: left;
                  color: #3A314C;
                  font-style: italic;
                }
                
                .people-name {

                  font-family: 'Raleway';
                  font-size: 24px;
                  line-height: 34px;
                  text-align: left;
                  color: #3A314C;

                  span {
                      font-family: 'Raleway';
                      font-size: 20px;
                      line-height: 34px;
                      display: flex;
                      align-items: left;
                      color: #5A536B;
                    }

                }
          }
        }

    }

    .people-img {
      width: 500px;
      height: 500px;
    }

    .second{
      height: 100%;

    }
  }
}

.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  0% {
     opacity: 0;
     -webkit-transform: translateX(100%);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
     opacity: 0;
     transform: translateX(100%);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
     opacity: 0;
     -webkit-transform: translateX(0);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(100%);
  }
}

@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(-100%);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}

@-webkit-keyframes fadeOutLeft {
  0% {
     opacity: 0;
     -webkit-transform: translateX(0);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(-100%);
  }
}

@keyframes fadeOutLeft {
  0% {
     opacity: 0;
     transform: translateX(0);
  }
  100% {
     opacity: 1;
     transform: translateX(-100%);
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
     opacity: 0;
     -webkit-transform: translateX(0);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateX(-100%);
  }
}

@keyframes fadeOutRight {
  0% {
     opacity: 0;
     transform: translateX(-100%);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}


.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}