
a{
    text-decoration: none;
}

.button-let-connect-div{
    display: flex;
    height: 80px;
    width: fit-content;
    a{
        margin: auto;
    }
}

.botton-container-small{
    transform: scale(0.6);
    margin-left: -50px !important;
}

.botton-container{

    display: flex;
    width: fit-content;
    border-style: solid;
    border-color: transparent;
    margin-top: 25px;
    border-width: 1px;
    border-radius: 52px;
    padding-right: 30px;
    transition-delay: 0.3s;
    animation: none;

    
    &:hover{
        border-style: solid;
        border-color: #FFFFFF;
        border-radius: 50px;
        padding-right: 30px;

        .send-arrow{
            width: 52px;
            height: 100%;
            transform: translateX(15px);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../images/arrow.svg');
            background-color: #4A4949;
        }
    }

    .button{
        display: flex;
        flex-direction: row;
        position: relative;
        width: fit-content;

        .circle-hide{
            visibility: hidden !important;
        }
        
        .circle{
            background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
            background-size: 200% 200%;
            border-radius: 50px;
            display: flex;
            visibility: visible;
            position: absolute;
            width: 70px;
            height: 70px;
            animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
        }
    
        &:hover{

            .circle{
            animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
            visibility: visible !important; 
            left: calc(100% - 40px);
            }

            .circle-hide{
                visibility: visible !important;
            }

            .right-arrow{
                width: 52px;
                height: 100%;
                transform: translateX(14px);
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('../../images/right-arrow-white.svg');
            }

        }
    
        .button-label{
            color: #FFFFFF;
            height: 70px;
            text-indent: 35px;
            line-height: 70px;
            text-align: left;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            position: relative;
            white-space: nowrap;
            font-family: 'raleway';
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
        }

        .button-label-small{
            color: #FFFFFF;
            width: fit-content !important;
            height: 40px !important;
            text-indent: 20px !important;;
            line-height: 40px !important;
            font-size: 16px !important;
            line-height: 123%;
        }
    
        .button-icon{
            margin-left: 20px;
        }
        
        .button-icon-small{
            background-color: #4A4949 !important;
            margin-left: 10px !important;
            transform: scale(0.6) !important;
        }

        .right-arrow{
            width: 52px;
            height: 100%;
            transform: translateX(14px);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../images/right-arrow.svg');
        }

        .send-arrow{
            width: 52px;
            height: 100%;
            transform: translateX(15px);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../images/arrow.svg');
        }
    }
}

.button-minor-outer{
    display: inline-flex;
    flex-direction: row;
    margin-top: 10px;
    padding-right: 8px;
    border-radius: 51px;
    height: 38px;
    border-width: 1px;
    padding: 1px;
    border-style: none;
    border-color: transparent;

    &:hover {
        transition-delay: 0.2s;
        padding: 0px;
        padding-right: 7px;
        border-style: solid;
        border-color: white;

        .minor-arrow{
            transition-delay: 0.4s;
            width: 20px;
            height: 20px;
            z-index: 3;
            background-image: url('../../images/right-arrow-small-white.svg');
        }
    }

    .button-minor {
    
        // margin-top: 10px;
        display:inline-flex;
        flex-direction: row;
        justify-content: left;
        line-height: 130%;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        white-space: nowrap;
        text-transform: uppercase;
    
        span {
            padding: 0px 11px;
            position: relative;
            z-index: 3;
        }
    
        &:hover {
    
            &::before {
                animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                left: calc(100%);
                z-index: 0;
            }
    
        }
    
        &::before {
            // background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
            background-size: 400% 400%;
            border-radius: 50px;
            content: '';
            display: inline-block;
            height: 36px;
            position: absolute;
            width: 36px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
        }
    
        .button-label{
            font-family: 'Raleway';
            font-weight: 600;
            padding-right: 10px;
            font-size: 20px;
            line-height: 123%;
            display: flex;
            align-items: center;
            letter-spacing: 0.16em;
            color: #4A4949;
            z-index: 3;
        }
    
    }

    .minor-arrow{
        width: 20px;
        height: 20px;
        background-image: url('../../images/right-arrow-small.svg');
    }

    .button-icon{
        display:flex;
        flex-direction: row;
        margin-left: 8px;
        margin-top: 9px;
    }

}

.button-let-connect-outer{
    display: flex;
    padding-right: 28px;
    padding-left: 2px;
    border-radius: 51px;
    transition-delay: 0.3s;

    &:hover {
        transition-delay: 0.3s;
        border-radius: 51px;
        border-style: solid;
        padding-top: 0px;
        border-color: #FFFFFF;
    }

    .more-button {
        color: map-get($colors, 'white');
        display: inline-flex;
        height: 70px;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
    
        &:hover {
            &::before {
                animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                left: calc(100% - 42px);
            }
    
            .star-icon {
                transition-delay: 0.3s;
                transform: none !important;
                transform: translateX(10px);
                // animation: starAnimation 0.9s ease-in-out infinite,backgroundAnimation 5s ease-in-out infinite;
                position: relative;
            }
        }
    
        &::before {
            background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
            background-size: 400% 400%;
            border-radius: 50px;
            content: '';
            display: inline-block;
            height: 70px;
            position: absolute;
            width: 70px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
        }
    
        span {
            padding: 0 35px 0 25px;
            position: relative;
            z-index: 3;
        }
    
        .star-icon {
            // animation: starAnimation 0.9s ease-in-out infinite;
            position: relative;
            transform: translateX(10px);
        }
    }
}

.button-outer{
    display: flex;
    width: fit-content;
    margin-top: 30px;
    padding-right: 26px;
    padding: 1px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    border-radius: 50px;
    border-style: none;

    &:hover {
        transition-delay: 0.2s;
        border-style: solid;
        border-color: white;
        padding: 0px;
        padding-right: 25px;
    }

    .button-circle{
        height: 70px;
        border-radius: 51px;
        padding-left: 20px;
        justify-content: center;
        display:inline-flex;
        flex-direction: row;
        justify-content: left;
        line-height: 130%;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        white-space: nowrap;
        text-transform: uppercase;
    
        .button-label{
            font-family: 'Raleway';
            font-weight: 600;
            padding-right: 10px;
            font-size: 20px;
            line-height: 123%;
            display: flex;
            align-items: center;
            letter-spacing: 0.16em;
            color: #ffffff;
            z-index: 3;
    
            span {
                padding: 0 35px 0 25px;
                position: relative;
                z-index: 3;
            }
        }
    
        &::before {
            background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
            background-size: 400% 400%;
            border-radius: 50px;
            content: '';
            display: inline-block;
            position: absolute;
            height: 68px;
            width: 68px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
        }
    
        &:hover {
    
            &::before {
                animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                left: calc(100% - 42px);
                z-index: 0;
            }

            .right-arrow{
                width: 40px;
                height: 14px;
                background-image: url('../../images/right-arrow-white.svg');
            }
    
        }
    
        .button-icon{
            background-repeat:no-repeat;
            justify-content: center;
            margin: auto;
            display:flex;
            flex-direction: row;
            transform: translateX(10px);
            background-repeat: no-repeat;
        }
    
        .right-arrow{
            width: 40px;
            height: 14px;
            background-image: url('../../images/right-arrow.svg');
        }

        .send-arrow-white{
            width: 40px;
            height: 40px;
            background-position: center;
            background-image: url('../../images/arrow.svg'); 
        }
    
        .send-arrow-black{
            width: 40px;
            height: 40px;
            background-position: center;
            background-image: url('../../images/send-arrow-black.svg'); 
        }
    
        .minor-arrow{
            width: 40px;
            height: 14px;
            background-image: url('../../images/right-arrow.svg');
        }
    
    }

}

@keyframes send-blink-animation {
    0% {
        opacity: 0.5;
        transform: scale(0.5);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        transform: translateX(2px);
        opacity: 0.5;
        transform: scale(0.5);
    }
}

@media only screen and (max-width: map-get($brakpoint, 'xl')) {

    .botton-container{
        transform: scale(0.6) !important;
        margin-left: -63px !important;
        width: fit-content !important;
    }

    .botton-container-small{
        transform: scale(0.6)!important;
        margin-left: -50px !important;
    }

    .button-outer{
        display: inline-flex;
        width: fit-content;
        padding: 1px;
        padding-right: 15px;
        border-style: solid;
        border-color: transparent;
        border-width: 1px;
        border-radius: 50px;
        border-style: none;
        border-color: transparent;

    
        &:hover {
            border-style: solid;
            border-color: white;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    
        .button-circle{
            height: 45px;
            border-radius: 51px;
            padding-left: 20px;
            justify-content: center;
            display:inline-flex;
            flex-direction: row;
            justify-content: left;
            line-height: 130%;
            cursor: pointer;
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            text-transform: uppercase;
        
            .button-label{
                font-family: 'Raleway';
                font-size: 16px;
                line-height: 123%;
                display: flex;
                align-items: center;
                letter-spacing: 0.13em;
                color: #FFFFFF;
                z-index: 3;
        
                span {
                    padding: 0 35px 0 25px;
                    position: relative;
                    z-index: 3;
                }
            }
        
            &::before {
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                background-size: 400% 400%;
                border-radius: 50px;
                content: '';
                display: inline-block;
                position: absolute;
                height: 45px;
                width: 45px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
            }
        
            &:hover {
        
                &::before {
                    animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                    background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                    left: calc(100% - 30px);
                    z-index: 0;
                }
    
                .right-arrow{
                    width: 40px;
                    height: 14px;
                    background-image: url('../../images/right-arrow-white.svg');
                }
        
            }
        
            .button-icon{
                background-repeat:no-repeat;
                justify-content: center;
                margin: auto;
                display:flex;
                flex-direction: row;
                transform: translateX(10px);
                background-repeat: no-repeat;
            }
        
            .right-arrow{
                width: 40px;
                height: 14px;
                background-image: url('../../images/right-arrow.svg');
            }
    
            .send-arrow-white{
                width: 40px;
                height: 40px;
                background-position: center;
                background-image: url('../../images/arrow.svg'); 
            }
        
            .send-arrow-black{
                width: 40px;
                height: 40px;
                background-position: center;
                background-image: url('../../images/send-arrow-black.svg'); 
            }
        
            .minor-arrow{
                width: 40px;
                height: 14px;
                background-image: url('../../images/right-arrow.svg');
            }
        
        }
    
    }

    .button-let-connect-outer{
        display: flex;
        padding-right: 15px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-width: 1px;
        transition-delay: 0.4ms;

        &:hover {
            transition-delay: 0.4ms;
            border-radius: 51px;
            border-style: solid;
            border-color: #FFFFFF;
        }
    
        .more-button {
            color: map-get($colors, 'white');
            display: inline-flex;
            height: 45px;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 600;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
        
            &:hover {
                &::before {
                    animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                    left: calc(100% - 30px);
                }
        
                .star-icon {
                    animation: starAnimation 0.9s ease-in-out infinite;
                    position: relative;
                }
            }
        
            &::before {
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                background-size: 400% 400%;
                border-radius: 50px;
                content: '';
                display: inline-block;
                height: 45px;
                position: absolute;
                width: 45px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
            }
        
            span {
                padding: 0 35px 0 25px;
                position: relative;
                z-index: 3;
            }
        
            .star-icon {
                // animation: starAnimation 0.9s ease-in-out infinite;
                position: relative;
            }
        }
    }

}

@media only screen and (max-width: map-get($brakpoint, 'md')) {

    .botton-container{
        transform: scale(0.65) !important;
        margin-left: -63px !important;
        width: fit-content !important;
    }

    .botton-container-small{
        transform: scale(0.55)!important;
        margin-left: -50px !important;
    }

    .button-outer{
        display: inline-flex;
        width: fit-content;
        padding: 1px;
        padding-right: 15px;
        border-style: solid;
        border-color: transparent;
        border-width: 1px;
        border-radius: 50px;
        border-style: none;
        border-color: transparent;
    
        &:hover {
            border-style: solid;
            border-color: white;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    
        .button-circle{
            height: 45px;
            border-radius: 51px;
            padding-left: 20px;
            justify-content: center;
            display:inline-flex;
            flex-direction: row;
            justify-content: left;
            line-height: 130%;
            cursor: pointer;
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            text-transform: uppercase;
        
            .button-label{
                font-family: 'Raleway';
                font-size: 16px;
                line-height: 123%;
                display: flex;
                align-items: center;
                letter-spacing: 0.13em;
                color: #FFFFFF;
                z-index: 3;
        
                span {
                    padding: 0 35px 0 25px;
                    position: relative;
                    z-index: 3;
                }
            }
        
            &::before {
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                background-size: 400% 400%;
                border-radius: 50px;
                content: '';
                display: inline-block;
                position: absolute;
                height: 45px;
                width: 45px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
            }
        
            &:hover {
        
                &::before {
                    animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                    background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                    left: calc(100% - 30px);
                    z-index: 0;
                }
    
                .right-arrow{
                    width: 40px;
                    height: 14px;
                    background-image: url('../../images/right-arrow-white.svg');
                }
        
            }
        
            .button-icon{
                background-repeat:no-repeat;
                justify-content: center;
                margin: auto;
                display:flex;
                flex-direction: row;
                transform: translateX(10px);
                background-repeat: no-repeat;
            }
        
            .right-arrow{
                width: 40px;
                height: 14px;
                background-image: url('../../images/right-arrow.svg');
            }
    
            .send-arrow-white{
                width: 40px;
                height: 40px;
                background-position: center;
                background-image: url('../../images/arrow.svg'); 
            }
        
            .send-arrow-black{
                width: 40px;
                height: 40px;
                background-position: center;
                background-image: url('../../images/send-arrow-black.svg'); 
            }
        
            .minor-arrow{
                width: 40px;
                height: 14px;
                background-image: url('../../images/right-arrow.svg');
            }
        
        }
    
    }

    .button-let-connect-outer{
        display: flex;
        padding-right: 15px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-width: 1px;
        transition-delay: 0.4ms;

        &:hover {
            transition-delay: 0.4ms;
            border-radius: 51px;
            border-style: solid;
            border-color: #FFFFFF;
        }
    
        .more-button {
            color: map-get($colors, 'white');
            display: inline-flex;
            height: 45px;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 600;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
        
            &:hover {
                &::before {
                    animation: iconAnimationIn 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                    left: calc(100% - 30px);
                }
        
                .star-icon {
                    animation: starAnimation 0.9s ease-in-out infinite;
                    position: relative;
                }
            }
        
            &::before {
                background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%));
                background-size: 400% 400%;
                border-radius: 50px;
                content: '';
                display: inline-block;
                height: 45px;
                position: absolute;
                width: 45px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
            }
        
            span {
                padding: 0 35px 0 25px;
                position: relative;
                z-index: 3;
            }
        
            .star-icon {
                // animation: starAnimation 0.9s ease-in-out infinite;
                position: relative;
            }
        }
    }

}