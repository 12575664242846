.proceed-freston-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;

    @include md{
        height: fit-content;
    }
// inner-cointainer
    .cointainer{
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;
        // width: 100%;
        // margin-right: 150px;

        .section-container:nth-child(1){
            padding-left: 80px;
        }

        .section-container{
            margin: auto;
            width: 50%;
            display: flex;
            flex-direction: column;


            .slider-padding{
                margin: auto;
            }

            .section-inner-container{
                margin: auto;
                padding-left: 64px;
                .section-title{
                    padding-right: 150px;
                }
                .section-text{
                    padding-right: 200px;
                }
            }


        }
    }
    

}

.core-values {

    .section-title{
        padding-top: 100px;
    }
}


.proceed-best-chioce-section{
    height: 100vh;

    .title{
        margin-top: 90px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        line-height: 110%;
        color: #5A536B;
        span{
          color: #F3684A;
          font-weight: 600px;
        }
      }

    .description{
        font-family: 'Raleway';
        font-size: 1.25rem;
        line-height: 30px;
        color: #333333;
        p{
            padding-top: 20px;
        }
    }

    .right-logo-proceed{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 60px;
        
        div{
            display: flex;
            flex-direction: row;
            div{
                display: flex;
                flex-direction: column;
                margin: 5px;
                height: 200px;
                width: 242px;
                img{
                    width: 100px;
                    height: 100px;
                }
            }
            .logo-description{
                padding-top: 25px;
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 130%;
                color: #3A314C;
            }
        }
    }
}

.words-from-people-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: white !important;
}

@media only screen and (max-width: 960px){

    // .inner-cointainer{
    //     margin-right: 0px !important;
    // }

    .proceed-freston-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;
    
        @include md{
            height: fit-content;
            padding: 64px 20px;
        }

        .cointainer{

            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
    
            .section-container:nth-child(1){
                display: none;
            }
    
            .section-container{
                margin: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
    
                .slider-padding{
                    margin: auto;
                }
    
                .section-inner-container{
                    margin: auto;
                    padding-left: 0px;
                    .section-title{
                        padding-right: 0px;
                        font-size: 24px;
                    }
                    .section-text{
                        padding-right: 0px;
                        font-size: 18px;
                    }
                }
            }
        }
        
    
    }


    .words-from-people-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        width: 100%;
    }


    .core-values {

        .section-title{
            padding-top: 0px;
        }
    }

    .proceed-best-chioce-section{
        height: 100%;
        padding-top: 64px;

        .right-logo-proceed{
            height: fit-content;
            padding-top: 64px;
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 60px;
            
            div{
                display: flex;
                flex-direction: row;
                div{
                    display: flex;
                    flex-direction: column;
                    margin: 5px;
                    height: 200px;
                    width: 242px;
                    padding: 5px;
                    img{
                        width: 80px;
                        height: 80px;
                    }
                }
                .logo-description{
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 130%;
                    color: #3A314C;
                }
            }
        }

        .title{
            margin-top: 0px;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 110%;
            color: #5A536B;
            span{
              color: #F3684A;
              font-weight: 600px;
            }
          }

        .description{
            font-family: 'Raleway';
            font-size: 18px;
            line-height: 30px;
            color: #333333;
            p{
                padding-top: 20px;
            }
        }
    }
}