

.life-at-freston-gallery{

    width: 100%;

    .gallery-container{
        margin-top: 100px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: row;

        .gallery-left-side{
            display: flex;
            flex-direction: column;
            width: 442px ;

            .navigation-container {
                padding-top: 100px;
                display: flex;
                flex-direction: row;
                justify-content: left;

                img{
                    cursor: pointer;
                }
            }

            .gallary-focus-item {
                margin-top: 10px;
                width: 440px;
                height: 322px;
                border-radius: 6px;
            }
    
            .gallery-focus-item-date {
            font-family: 'Raleway';
            font-size: 16px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: #5A536B;
            }
            
            .gallery-focus-item-label {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 120%;
            display: flex;
            align-items: center;
            color: #3A314C;
            }
        }



        .gallery-right-side{
            width: 60% ;
            display: flex;
            height: fit-content;
            flex-direction: column;
            padding-left: 20px;


            .gallery-item-container {
                padding-top: 50px;
                display: flex;
                flex-direction: row;
                height: 345px;
                
                .gallery-item {
                    width: 500px;
                    height: 200px;
                    margin-right: 20px;
                }
            }

            .gallery-title {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 500;
                font-size: 50px;
                line-height: 130%;
                align-items: center;
                text-align: left;
                color: #363636;
                span {
                    color: #F3684A;
                  }
                  
              }
            
              .gallery-text {
                font-family: 'Raleway';
                font-size: 20px;
                display: flex;
                text-align: left;
                padding-right: 100px;
                align-items: center;
                color: #7E7E7E;
              }


        }
    }
}

// .life-at-freston-gallery {
//     width: 100%;
//     height: 80vh;
//     margin: auto;
    
//     .gallery-container{
//         display: flex;
//         flex-wrap: wrap;
//         flex-direction: row;

//         .gallery-left-side {
//             width: 40%;
//             height: 75vh;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             padding-right: 20px;
//             .social-media-container{
//                 padding-bottom: 100px;
//                 width: 100%;
//             }
//             .gallary-focus-item {
//                 width: 500px;
//                 height: 322px;
//                 background: #D9D9D9;
//             }
//           }

//           .gallery-focus-item-date {
//             font-family: 'Raleway';
//             font-size: 16px;
//             line-height: 150%;
//             display: flex;
//             align-items: center;
//             color: #5A536B;
//             }

//             .gallery-focus-item-label {
//             font-family: 'Raleway';
//             font-style: normal;
//             font-weight: 600;
//             font-size: 32px;
//             line-height: 120%;
//             display: flex;
//             align-items: center;
//             color: #3A314C;
//             }
          
//           .gallery-right-side {
//             width: 60%;
//             display: flex;
//             height: 75vh;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             .gallery-item{
//                 margin-right: 10px;
//             }
//           }
//     }
// }

@media only screen and (max-width: map-get($brakpoint, 'md')){

    .life-at-freston-gallery{

        .gallery-container{
            display: flex;
            flex-direction: column;

            .gallery-left-side{
                display: none;
                flex-direction: column;
                background-color: #F3684A;
                width: 50% ;

                .navigation-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    padding-bottom: 30px;
                }
            }

            .gallary-focus-item {
                width: 500px;
                height: 322px;
                background: #D9D9D9;
            }

            .gallery-focus-item-date {
            font-family: 'Raleway';
            font-size: 16px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: #5A536B;
            }

            .gallery-focus-item-label {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 120%;
            display: flex;
            align-items: center;
            color: #3A314C;
            }
    
            .gallery-right-side{
                width: 100% ;
                padding: 20px;
                height: fit-content;
             
                .gallery-item-container {
                    display: flex;
                    flex-direction: row;

                    .gallery-item {
                        display: flex;
                        width: 236px;
                        height: 224px;
                        flex-shrink: 0;
                        margin-right: 20px;
                        background: #D9D9D9;
                      }

                }
    
                .gallery-title {
                    color: #3A314C;
                    // color: #F3684A;
                    font-size: 26px;
                    font-family: Raleway;
                    font-weight: 500;
                    line-height: 130%;
                    span {
                        color: #F3684A;
                      }
                      
                  }
                
                  .gallery-text {
                    font-family: 'Raleway';
                    font-size: 16px;
                    line-height: 150%;
                    padding-right: 0px;
                    display: flex;
                    text-align: left;
                    align-items: center;
                    color: #7E7E7E;
                  }
            }
        }
    }

}


@media only screen and (max-width: map-get($brakpoint, 'xxl')){

    .gallery-container{

        .gallery-left-side {
            .gallary-focus-item{
                width: 28vw;
            }
        }

        .gallery-right-side {
            height: 75vh;
            padding-left: 10px;
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 0;

            .gallery-item{
                margin-top: 60px;
                margin-right: 0px;
            }
          }
    }

}