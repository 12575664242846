@media only screen and (max-width: 960px) {
  
    .breadcrumb-container{
      display: none;
    }

    /* .placeholder{
      height: 0px;
      display: none;
      background: transparent;
    } */

    .contact-us-right-display-pic{
      display: none;
    }
    .contact-us{
      width: 100%;
      height: 100%;
    }
    .form-container{
      padding: 20px;
      margin-top: 100px;
    }

    .card-description{
      font-family: 'Raleway';
      font-size: 16px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
    }

    .card-bullet-item{
      display: flex;
      padding: 2px;
      font-family: 'Raleway';
      font-size: 16px;
      line-height: 150%;
      display: flex;
      height: 100%;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);
    }


    .service-industry-card{
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .card-mini-titile{
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 123%;
      display: flex;
      align-items: center;
      letter-spacing: 0.16em;
      color: #000000;
    }

    .card-titile{
      font-family: 'Raleway';
      font-style: normal;
      text-align: left;
      overflow-wrap: break-word;
      color: #3A314C;
      font-weight: 500;
      font-size: 26px;
      line-height: 130%;
    }

    .our-industry-card-section{
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      background: #FFFFFF;
    }

    .service-industry-card-container{
      padding-top: 64px;
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%
    }

    .service-industry-card-right{
      padding-right: 0px;
      width: 100%;
      height: auto;
    }

    .service-industry-card-left{
      width: 100%;
      height: auto;
    }
    .service-industry-description{
      margin-left: 0px;
      height: 100%;
    }
    .service-industry-desktop-img{
      display: none;
    }

    .description-inner-container{
     width: 100%;
    }

    .mobile-display{
      display: flex;
    }


    .inner-card-feature {
      font-family: 'Raleway';
      font-size: 16px;
      line-height: 150%;
      display: flex;
      color: rgba(0, 0, 0, 0.6);
    }

    .inner-card-insight-caption{
      font-size: 16px;
    }

    .mobile-inner-card-container{
      background-repeat:no-repeat ;
      display: flex;
      margin-top: 30px;
      flex-direction: column;
      width: 100vw;
      padding-top: 100px;
    }

    .description-inner-container{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    
    .large-display{
      display: none;
    }

    .inner-card-author{
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .inner-card-words{
      font-family: 'Raleway';
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      width: auto;
      display: flex;
      align-items: center;
      color: #7E7E7E;
    }

    .card-mobile{
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      width: 90%;
      border-radius: 6px;
      height: fit-content;
    }

    .bullet-icon-container{
      height: 100%;
    }

    /* Let Connect */
    .let-connect-us{
      flex-direction: column;
    }


  }