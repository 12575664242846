  @keyframes cross-fade-blur{
    0% {
      opacity: 0;
      filter: blur(1rem);
      transform: translate3d(0, 3rem, 0) rotate3d(0.25, -1, 0, 66.6deg);
    }
    20%,
    100% {
      opacity: 1;
      filter: blur(0px);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fade-animate{
    0% {
        filter: blur(1rem);
        opacity: 0.5;
    };
    100% {
        filter: blur(0px);
    }
}


@keyframes close-animate{
  0% {
      height: 100%;
      // filter: blur(1rem);
  };
  30% {
    height: 30px;
  };
  100% {
      height: 0%;
      // filter: blur(0px);
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

  .breadcrumb-slNo{
    text-align: end;
    background-color: transparent !important;
    border-style: none !important;
    font-family: Raleway;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px
    }
    
  .breadcrumb-title{
    background-color: transparent !important;
    border-style: none !important;
    text-align: right;
    font-family: Raleway;
    font-weight: 500;
    height: fit-content;
    font-size: 18px;
    line-height: 23px;
  
    }

    .breadcrumb-conttent-active{
      display: flex;
      justify-content: end;
      margin-left: auto;
      margin-top: 16px;
      width: 110px;
      min-height: 13px;
      background-color: rgba(255, 255, 255, 0.7);
    }

.breadcrumb-container{
    z-index: 500;
    position: absolute;
    right: 40px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // pointer-events: none;


    .breadcrumb{
        width: 200px;
        margin: auto;
        display: flex;
        flex-direction: column;

        .breadcrumb-bar-active{
          display: flex;
          margin-left: auto;
          justify-content: end;
          width: 150px;
          cursor: pointer;
          background-color: transparent !important;
          border-style: none !important;
          
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              animation-delay: $i * 0.04ms;
            }
          }

          .breadcrumb-content{
            display: flex;
            visibility: visible;
            margin-top: 10px;
            flex-direction: column;
            // animation: open-animate 0.4s ease-out ;
            background-color: transparent !important;
            border-style: none !important;
            animation: fade-in 1s !important;
          }

        }

        .breadcrumb-bar{
            display: flex;
            margin-left: auto;
            justify-content: end;
            width: 110px;
            min-height: 13px;
            margin-left: auto;
            text-align: right;
            margin-top: 16px;
            line-height: 13px;
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.7);
            // transition-duration: 0.5s;

            &:hover{
              border-style: none !important ;
              background-color: transparent !important;

              .breadcrumb-content{
                transition: all 0.5s ease;
                padding-top: 20px;
                visibility: visible;
                display: flex;
                height: max-content;
                flex-direction: column;
                background-color: transparent !important;
                border-style: none !important;
                animation: fade-in 1s !important;
              }

            }

            .breadcrumb-content{
              display: flex;
              height: 0px;
              visibility: hidden;
            }
        }

       }

  }
  