// Page Loading ----------------
.loading {
    background: linear-gradient(134.78deg, #EC4F8D 4.46%, #D04374 47.99%, #C74070 54.76%, #A5335B 97.32%);
    height: 100vh;
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    overflow: hidden;
    transform: scale(1);

    &.hide {
        transform: scale(2);
        opacity: 0;
        transition: all 2000ms cubic-bezier(0.075, 0.82, 0.165, 1);
        animation: scaleAnimation 0.5s;
        filter: blur(50px);
        pointer-events: none;
    }

    .content {
        width: 120px;
        display: block;
        position: relative;
        margin: auto;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .circle {
        margin: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: map-get($colors, 'white');
        opacity: 0;
        -webkit-animation: pulse 2s linear infinite alternate;
        animation: pulse 2s linear infinite alternate;
        opacity: 0;

        &:nth-child(6) {
            opacity: 0 !important;
            visibility: hidden;
        }

        &:nth-child(1) {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }

        &:nth-child(2) {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }

        &:nth-child(3) {
            -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
        }

        &:nth-child(4) {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }

        &:nth-child(5) {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }

        &:nth-child(7) {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
    }


}


main {
    height: 0;
    overflow: hidden;
    transition: all 2000ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &.show {
        height: auto;
        transition: all 2000ms cubic-bezier(0.075, 0.82, 0.165, 1);
        overflow: visible;
    }
}

@keyframes pulse {

    from,
    40% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    80%,
    to {
        opacity: 1;
    }
}