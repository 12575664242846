.blog-page-section {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    width: 100%;
    background-color: #F5F5F5;
}

.blog-page-section .title,
.sub-title {
  margin: auto;
  justify-content: center;
  text-align: center;
}

.blog-inner-page-related-blog-section {
    display: flex;
    flex-direction: row;
    padding: 50px 0px;
    background-color: #F9F9F9;

    .section-container{
        margin: auto;
        width: 100%;
        height: fit-content;
        display: flex;
        max-width: 1600px;
        flex-direction: column;
        padding-top: 0px;

        .inner-container{
            display: flex;
            max-width: 1210px;
            height: fit-content;
            width: 100%;
            flex-direction: column;
            margin: auto;

            .head{
                font-family: Raleway;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.16em;
                text-align: left;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 30px 0px;

                div{
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }

            .body{
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;


                .item-container{
                    display: flex;
                    flex-direction: row;
                    width: 50%;
                    padding-right: 20px;
                }

                .blog-display-pic {
                    height: 300px;
                    width: 50%;
                    margin-right: 15px;
                }

            }
        }

    }
}

.blog-inner-page-blog-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px;

    .section-container{
        margin: auto;
        width: 100%;
        height: fit-content;
        display: flex;
        max-width: 1600px;
        display: flex;
        flex-direction: column;
        padding-top: 64px;

        .inner-container{
            display: flex;
            max-width: 1210px;
            height: fit-content;
            width: 70%;
            flex-direction: column;
            margin: auto;
            font-family: Raleway;

            .info-title{
                padding: 10px 0px;
            }

            h5{
                padding: 10px 0px;
            }

            h3{
                font-size: 20px;
                font-weight: 500;
            }

            h4{
                font-size: 20px;
                padding: 10px 0px;
            }


            li{
                font-size: 16px;
                line-height: 150%;
                font-family: Raleway;
            }
        }

    }

}

.blog-page-section .container {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 80%
}


.blog-display-pic{
    border-radius: 6px;
    object-fit: cover;
    cursor: pointer;
}

.item-container{
    padding-bottom: 20px;
}

.blog-author {
    font-family: 'Raleway';
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #000000;
}
  
.blog-titile {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
}
  
.blog-description {
    font-family: 'Raleway';
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #5A536B;
}

.blog-recent-blog-section{

    display: flex;
    flex-direction: column;
    background-color: #fbfbfb;

    .section-container{
        
        margin: auto;
        width: 100%;
        height: fit-content;
        display: flex;
        max-width: 1600px;
        display: flex;
        flex-direction: column;
        padding-top: 64px;

        .inner-container{
            display: flex;
            max-width: 1210px;
            height: fit-content;
            width: 100%;
            flex-direction: column;
            margin: auto;

            .head{
                font-family: Raleway;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.16em;
                text-align: left;
            }

            .body{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: row;

                .body-item{
                    width: 50%;

                    &:nth-child(1){
                        display: flex;
                        flex-direction: column;
                        padding-right: 20px;

                        .item-container{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                        
                      
                        .blog-display-pic {
                            height: 350px;
                            width:  100%;
                            margin-bottom: 10px;
                        }
                    }
                
                    &:nth-child(2){
                        display: flex;
                        flex-direction: column;

                        .item-container{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                        }

                        .blog-display-pic {
                            height: 300px;
                            width: 50%;
                            margin-right: 15px;
                        }
                    }

                }

            }
        }
        

    }

}

.blog-more-blog-section{
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    background-color: #fbfbfb;

    .section-container{
        margin: auto;
        width: 100%;
        height: fit-content;
        display: flex;
        max-width: 1600px;
        display: flex;
        flex-direction: column;
        padding-top: 64px;

        .inner-container{
            display: flex;
            max-width: 1210px;
            height: fit-content;
            width: 100%;
            flex-direction: column;
            margin: auto;

            .head{
                font-family: Raleway;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.16em;
                text-align: left;
            }

            .body{
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;


                .item-container{
                    display: flex;
                    flex-direction: row;
                    width: 50%;
                    padding-right: 20px;
                }

                .blog-display-pic {
                    height: 300px;
                    width: 50%;
                    margin-right: 15px;
                }

            }
        }


    }


}

// max-width: map-get($brakpoint, 'lg');

// .blog-more-blog-section {
//     display: flex;
//     flex-direction: column;
//     padding-top: 30px;
//     background-color: #F9F9F9;
//   }
  
//   .blog-more-blog-section .more-blog-item {
//     display: flex;
//     flex-direction: row;
//     width: 50%;
//     padding: 10px;
//     height: 100%;
//     cursor: pointer;
//   }
  
//   .blog-more-blog-section .more-blog-item>div {
//     margin-left: 20px;
//   }
  
//   .blog-inner-page-related-blog-section,
//   .blog-more-blog-section .related-blog-body {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(249, 249, 249, 1);
//   ;
//   }


@media only screen and (max-width: map-get($brakpoint, 'xl')) {

    .blog-page-section {
        display: flex;
        flex-direction: column;
        min-height: 40vh;
        width: 100%;
        background-color: #F5F5F5;
    }  
    
    .blog-page-section .container {
        margin: auto;
        display: flex;
        flex-direction: column;
        width: 80%
    }
    
    .blog-page-section .title,
    .sub-title {
      margin: auto;
      justify-content: center;
      text-align: center;
    }
    
    .blog-display-pic{
        border-radius: 6px;
    }
    
    .item-container{
        padding-bottom: 20px;
    }
    
    .blog-author {
        font-family: 'Raleway';
        font-style: normal;
        font-size: 14px;
        font-weight: 300;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #000000;
    }
      
    .blog-titile {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #000000;
    }
      
    .blog-description {
        font-family: 'Raleway';
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #5A536B;
    }
    
    .blog-recent-blog-section{
    
        display: flex;
        flex-direction: column;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: map-get($brakpoint, 'lg');
            display: flex;
            flex-direction: column;
            padding-top: 64px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
    
                .head{
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }
    
                .body{
                    display: flex;
                    max-width: 1210px;
                    height: fit-content;
                    width: 100%;
                    flex-direction: row;
    
                    .body-item{
                        width: 50%;
    
                        &:nth-child(1){
                            display: flex;
                            flex-direction: column;
                            padding-right: 20px;
    
                            .item-container{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                            }
                            
                          
                            .blog-display-pic {
                                height: 300px;
                                width:  100%;
                                margin-bottom: 10px;
                            }
                        }
                    
                        &:nth-child(2){
                            display: flex;
                            flex-direction: column;
    
                            .item-container{
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
    
                            }
    
                            .blog-display-pic {
                                height: 100%;
                                width: 50%;
                                margin-right: 10px;
                            }
                        }
    
                    }
    
                }
            }
            
    
        }
    }
    
    .blog-more-blog-section{
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: map-get($brakpoint, 'lg');
            display: flex;
            flex-direction: column;
            padding-top: 64px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
    
                .head{
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }
    
                .body{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    width: 100%;
    
    
                    .item-container{
                        display: flex;
                        flex-direction: row;
                        width: 50%;
                        padding-right: 20px;
                    }
    
                    .blog-display-pic {
                        height: 280px;
                        width: 50%;
                        margin-right: 10px;
                    }
    
                }
            }
    
    
        }
    
    
    }
}


@media only screen and (max-width: map-get($brakpoint, 'md')){

    .blog-inner-page-related-blog-section {
        display: flex;
        flex-direction: row;
        padding: 50px 0px;
        background-color: #F9F9F9;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: map-get($brakpoint, 'sm');
            display: flex;
            flex-direction: column;
            padding-top: 0px;
    
            .inner-container{
                display: flex;
                max-width: map-get($brakpoint, 'sm');
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
                padding: 0px 20px;
    
                .head{
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16em;
                    text-align: left;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 30px 0px;
    
                    div{
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
    
                .body{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    width: 100%;
    
    
                    .item-container{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding-right: 0px;
                    }
    
                    .blog-display-pic {
                        height: 300px;
                        width: 100%;
                        margin-right: 0px;
                    }
    
                }
            }
    
    
        }
    }
    
    .blog-page-section {
        display: flex;
        flex-direction: column;
        min-height: 40vh;
        width: 100%;
    }  

    .botton-container{
        margin-top: -10px !important;
    }
    
    .blog-page-section .container {
        margin: auto;
        display: flex;
        flex-direction: column;
        width: 80%
    }
    
    .blog-page-section .title,
    .sub-title {
      margin: auto;
      justify-content: center;
      text-align: center;
    }
    
    .blog-display-pic{
        border-radius: 6px;
    }
    
    .item-container{
        padding-bottom: 30px;
    }
    
    .blog-author {
        font-family: 'Raleway';
        font-style: normal;
        font-size: 14px;
        font-weight: 300;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #000000;
    }
      
    .blog-titile {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #000000;
    }
      
    .blog-description {
        font-family: 'Raleway';
        font-size: 15px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #5A536B;
    }
    
    .blog-recent-blog-section{
    
        display: flex;
        flex-direction: column;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: map-get($brakpoint, 'sm');
            display: flex;
            flex-direction: column;
            padding-top: 64px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
                padding-left: 20px;
                padding-right: 20px;
    
                .head{
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }
    
                .body{
                    display: flex;
                    max-width: 1210px;
                    height: fit-content;
                    width: 100%;
                    flex-direction: column;
    
                    .body-item{
                        width: 100%;
                        padding-bottom: 20px;
    
                        &:nth-child(1){
                            display: flex;
                            flex-direction: column;
                            padding-right: 0px;
    
                            .item-container{
                                display: flex;
                                margin: auto;
                                flex-direction: column;
                                justify-content: space-between;
                            }
                            
                          
                            .blog-display-pic {
                                height: 300px;
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    
                        &:nth-child(2){
                            display: flex;
                            flex-direction: column;
    
                            .item-container{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
    
                            }
    
                            .blog-display-pic {
                                height: 300px;
                                width: 100%;
                                margin-right: 0px;
                            }
                        }
    
                    }
    
                }
            }
            
    
        }
    }
    
    .blog-more-blog-section{
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: map-get($brakpoint, 'sm');
            display: flex;
            flex-direction: column;
            padding-top: 64px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
                padding-left: 20px;
                padding-right: 20px;
    
                .head{
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }
    
                .body{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    width: 100%;
    
    
                    .item-container{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding-right: 0px;

                    }
    
                    .blog-display-pic {
                        height: 300px;
                        width: 100%;
                        margin-right: 0px;
                    }
    
                }
            }
    
    
        }
    
    
    }

    .blog-inner-page-blog-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 100px 20px;
    
        .section-container{
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            padding-top: 0px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
                font-family: Raleway;
    
                .info-title{
                    padding: 8px 0px;
                }
    
                h5{
                    padding: 8px 0px;
                }
    
                h3{
                    font-size: 18px;
                    font-weight: 500;
                }
    
                h4{
                    font-size: 18px;
                    padding: 10px 0px;
                }
    
    
                li{
                    font-size: 14px;
                    line-height: 150%;
                    font-family: Raleway;
                }
            }
    
        }
    
    }

}