

.full-display {
  height: 100vh;
}



*:focus:not(.focus-visible) {
  outline: none;
}

/* .top-header {
  padding: 20px 0px !important;
  top: 0px !important;
  background: transparent;
  transition: box-shadow 1s ease;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: 1s;
  transition-property: box-shadow;
} */

/* .on-scroll-header {
  background: transparent;
  backdrop-filter: blur(50px);
  box-shadow: 0 2px 8px 0 rgba(0, 10, 56, .12);
} */

/* .button{
  margin-top: 10px;
  display:flex;
  flex-direction: row;
  justify-content: left;
  line-height: 130%;
  cursor: pointer;
}

.button img{
  display:flex;
  flex-direction: row;
  margin-right: auto;
}

.button-label{
  font-family: 'Raleway';
  font-weight: 600;
  padding-right: 10px;
  font-size: 20px;
  line-height: 123%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16em;
  color: #4A4949;
} */

.product-list .image img {
  border-radius: 6px !important;
}

/* .testimonial .message {
  font-size: 16px !important;
  line-height: 24px !important;
  margin-left: 30px !important;
  font-style: italic;
} */

/* .section-text {
  font-size: 20px !important;
  line-height: 30px !important;
} */

.arrow-icon {
  width: '20px';
  height: '20px';
}

.slick-track {
  opacity: 1;
  height: 4928px;
  animation: mymove 5s infinite;
  transform: translate3d(0px, -1642px, 0px);
  transition: transform 5000ms linear 0s
}

.slick-track div {
  cursor: pointer;
}





.contact-us-button {
  margin-top: 20px;
  cursor: pointer;
}

.contact-us-button .more-button span {
  color: black;
}

.blog-load-more .more-button span {
  color: black;
}




.contact-us-button .more-button .star-icon {
  filter: brightness(10%) contrast(90%);
}

.blog-load-more .more-button .star-icon {
  filter: brightness(10%) contrast(90%);
}


.display-pic-contact-us {
  width: 400px;
  height: 400px;
  background-color: black;
  background: url(../images/background-banner-03.png);
}


.carrier-section,.blog-inner-page-section {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 200px;
}

/* .blog-inner-page-section .container{
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */



.blog-inner-page-section .title {
  margin: auto;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EEEEEE;
}

.blog-inner-page-section .sub-title {
  height: 93px;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 16px;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EEEEEE;
}

.more-blog-head,
.related-blog-head,
.recent-blog-head {
  font-family: 'Raleway';
  font-style: normal;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 123%;
}

/* .recent-blog-head{
  font-family: 'Raleway';
  font-style: normal;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 123%;
} */

/* .blog-inner-page-related-blog-section .related-blog-head{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 123%;
} */


.blog-recent-blog-section .recent-blog-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}



.blog-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.blog-item {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
}

.blog-item div {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  width: 50%;
  height: 100%;
}



.blog-recent-blog-section .recent-blog-left,
.recent-blog-right {
  width: 50%;
  height: 100%;
}

.blog-recent-blog-section .recent-main-blog-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
}

.blog-recent-blog-section .recent-blog-item>div {
  margin-left: 20px;
}

.recent-main-blog-item .blog-display-pic {
  width: 100% !important;
  height: 360px !important;
}

.blog-recent-blog-section .recent-blog-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  height: 100%;
  cursor: pointer;
}

.blog-load-more-section .container {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 80px;
}

/* .blog-inner-page-related-blog-section .blog-item{
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
} */

.blog-inner-page-section .sub-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #F5EDF1;
}

.carrier-section-let-us-know {
  margin-left: 200px;
  width: 585px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #525053;
}

.life-at-freston-section-let-us-know {
  margin-left: 200px;
  width: 650px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-size: 54px;
  line-height: 130%;
  padding-top: 107px;
  padding-bottom: 107px;
  display: flex;
  align-items: center;
  color: #525053;
  display: inline;
}

.button-center {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(20px);
  height: 100%;
}



.life-at-freston-gallery .navigation {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
}


.gallery-social {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-left: 0px;
}

.gallery-social ul {
  margin: 0px;
}

.gallery-social li {
  padding: 3px;
}

.gallery-know-us-more {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #7E7E7E;
}


.life-at-freston-section .title span {
  color: #F3684A;
}

.life-at-freston-section-let-us-know span {
  font-weight: 400;
}





.related-blog-item .explore {
  font-family: 'Raleway';
  margin-top: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 123%;
  display: flex;
  letter-spacing: 0.16em;
  color: #363636;
}

.blog-load-more {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.card-mini-titile {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 123%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16em;
  color: #000000;
}

.card-titile {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  text-align: left;
  overflow-wrap: break-word;
  color: #3A314C;
}

.card-titile span:last-child {
  font-weight: 400;
  color: #F3684A;
}

.card-titile span:not(:last-child) {
  font-weight: 400;
  color: #3A314C;
}

.card-description {
  margin-top: 50px;
  font-family: 'Raleway';
  font-style: normal;
  text-align: left;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.6);
}

.card-bullet-item {
  display: flex;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 150%;
  height: 27px;
  left: 21px;
  top: 0px;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.inner-card-container{
  display: flex;
}

.inner-card {
  display: flex;
  z-index: 1;
  height: 100%;
  flex-direction: column;
  margin-top: -190px;
  padding: 30px;
  border-radius: 6px;
}

.card-testimonial {
  width: 513px;
  height: 313px;
  backdrop-filter: blur(2px);
}

.card-insight {
  width: 408px;
  height: fit-content;
}

.inner-card-insight {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 123%;
  align-items: center;
  height: fit-content;
  letter-spacing: 0.16em;
  color: #000000;
}

.inner-card-insight-caption {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.inner-card .explore {
  font-family: 'Raleway';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 123%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16em;
  color: #000000;
}


.inner-card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-card .icon-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.inner-card .icon {
  margin-left: auto;
  margin-top: -70px;
}

.inner-card-author {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}


.card-section-dark {
  background: #F9F9F9;
}

.map-let-connect-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.map-let-connect-container>div{
  margin: auto;
}


.inner-card-words {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
  display: flex;
  font-style: italic;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.inner-card-author {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}


.container-padding {
  margin: 30px;
}

.description-inner-container {
  margin: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.service-industry-card-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%
}

.inner-card-feature {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.mobile-display {
  display: none;
}

.service-industry-desktop-img {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.service-industry-card-right {
  width: 50%;
  height: 100%;
}

.service-industry-card-left {
  width: 50%;
  height: 100%;
}





.contact-us-right-display-pic {
  margin-right: -100px;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* clip-path: ellipse(69% 65% at 69% 65%) */
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
}

.navigation-arrow {
  padding: 5px;
  height: 50px;
  height: 50px;
}

.location-map-container{
  background: url("../images/map.png") no-repeat center;
  background-color: #3A314C;
  height: 100%;
}

.location-mark{
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #998585;
  border-radius: 50%;
  cursor: pointer;
  border-color: #D0D0D0;
  border-style: solid;
}

.location-mark:hover{
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #F3684A;
  border-radius: 50%;
  border-color: #F3684A;
  border-style: solid;
}

.location-mark-inner-dot{
  width: 5px;
  height: 5px;
  background-color: white;
  margin: auto;
  border-radius: 50%
}

.location-mark:hover .location-mark-address{
  position: absolute;
  width: 357px;
  height: 211px;
  background: rgba(60, 52, 75, 0.5);
  border-radius: 8px;
  border-style: solid;
  border-color: #F3684A;
  display: flex;
  flex-direction: column
}

.location-mark-address{
  display: none;
  position: absolute;
  width: 357px;
  height: 211px;
  background: rgba(60, 52, 75, 0.5);
  border-radius: 8px;
  border-style: solid;
  border-color: #F3684A;
  flex-direction: column
}


