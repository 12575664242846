@use '../../../assets/sass/others/variables' as theme;

@use "sass:map";

$color_1: rgb(243, 104, 74);
$color_2: black;
$color_3: rgba(0, 0, 0, 0.6);

.fiveg-3gpp-specificaton{

    .introduction-section {
    
        display: flex;
        width: 100%;
        padding: 120px 0px 80px 0px;
        background-color: #F9F9F9;

    
        .section-container {

            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 860px;
                width: 100%;

                .platform{
                    transform: scale(1);
                    margin-top: 20px;
                    margin-left: -40px;
                }
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                }
    
                .container-image {
                    display: none;
                }

                .bullet-point-container{
                    display: flex;
                    flex-direction: column;
                    .bullet-item{
                        display: flex;
                        flex-direction: row;
                        padding: 5px;

                        .icon-container{
                            display: flex;
                            flex-direction: column;

                            .buttet-icon{
                                margin-top: 5px;
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .bullet-text{
                            margin-left: 8px;
                            font-family: Raleway;
                            font-size: 18px;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                    right: 140px;

                    .display-image {
                        position: relative;
                        width: 450px ;
                        height:  450px ;
                        transform: scale(1.3);
                    }
                }
    
            }

            .botton-container {
                margin-top: 60px !important;
            }
        }
    
    }

    .conclusion-section {
    
        display: flex;
        width: 100%;
        height: fit-content;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #ffffff;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 80px;
                padding-right: 40px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 60px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .container-image {
                    display: none;
                }


                .bullet-point-container{
                    display: flex;
                    flex-direction: column;
                    .bullet-item{
                        display: flex;
                        flex-direction: row;
                        padding-bottom: 10px;

                        .icon-container{
                            display: flex;
                            flex-direction: column;

                            .buttet-icon{
                                margin-top: 6px;
                                margin-bottom: auto;
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .bullet-text{
                            margin-left: 8px;
                            font-family: Raleway;
                            font-size: 18px;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
                
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: 547px;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    width: fit-content;
                    margin-right: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1; 
                    top: 70px;
                    left: 0px;
                }
    
                .circle-decoration:nth-child(2) {
                    width:  70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    right: 45px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
        }
    
    }

    .let-connect-us{

        .container{
            display: flex;
            flex-direction: column;

            .title-container{
                width: 100%;
            }
 
            .let-connect-title{
                height: auto;
              
                .title{
                    margin: auto;
                    font-size: 42px;
                    text-align: center;

                }

                width: 100%;
                display: block;
            }

            .button-container{
                transform: translateY(-80px);
                width: 100%;

                .button-center{

                    margin: auto;
                }
            }
        
        }
       
    }
    
}


@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .fiveg-3gpp-specificaton{
    
        .introduction-section {
    
            display: flex;
            width: 100%;
            padding: 120px 0px 80px 0px;
            background-color: #F9F9F9;
    
        
            .section-container {
    
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    max-width: 100%;
                    width: 100%;
                    margin: auto;
    
                    .platform{
                        transform: scale(1);
                        margin-top: 20px;
                        margin-left: -40px;
                    }
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22.14px;
                    }
        
                    .title {
                        font-size: 50px;
                        font-weight: 500;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
        
                    .container-image {
                        display: none;
                    }
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
                            padding: 5px;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin-top: 5px;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 18px;
                                line-height: 27px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                        right: 140px;
    
                        .display-image {
                            position: relative;
                            width: 450px ;
                            height:  450px ;
                            transform: scale(1.3);
                        }
                    }
        
                }
    
                .botton-container {
                    margin-top: 60px !important;
                }
            }
        
        }
    
        .conclusion-section {
    
            display: flex;
            width: 100%;
            height: fit-content;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #ffffff;
        
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    padding-right: 0px;
                    max-width: map-get(theme.$brakpoint, 'lg');
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22.14px;
                    }
        
                    .title {
                        font-size: 50px;
                        font-weight: 500;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 60px;
                        font-size: 18px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
    
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
                            padding-bottom: 10px;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin-top: 6px;
                                    margin-bottom: auto;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 18px;
                                line-height: 27px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
                    
        
                }
        
                .container-image {
                    width: 100%;
                    display: none;
        
                    .image-container {
                        height: 350px ;
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        width: fit-content;
                        margin-right: auto;
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1; 
                        top: 40px;
                        left: 0px;
                    }
        
                    .circle-decoration:nth-child(2) {
                        width:  50px;
                        height: 50px;
                        position: absolute;
                        bottom: 40px;
                        right: 20px;
                    }
        
                    .display-image {
                        width: 450px ;
                        height:  350px ;
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                    }
                }
            }
        
        }

        .let-connect-us{
    
            .container{
                display: flex;
                flex-direction: column;
    
                .title-container{
                    width: 100%;
                }
     
                .let-connect-title{
                    height: auto;
                  
                    .title{
                        margin: auto;
                        font-size: 42px;
                        text-align: center;
    
                    }
    
                    width: 100%;
                    display: block;
                }
    
                .button-container{
                    transform: translateY(-80px);
                    width: 100%;
    
                    .button-center{
    
                        margin: auto;
                    }
                }
            
            }
           
        }
        
    }
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .fiveg-3gpp-specificaton{
    
        .introduction-section {
        
            display: flex;
            width: 100%;
            padding: 120px 20px 60px 20px;
            background-color: #F9F9F9;
    
        
            .section-container {
    
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    max-width: 860px;
                    width: 100%;
    
                    .platform{
                        transform: scale(1);
                        margin-top: 20px;
                        margin-left: -40px;
                    }
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22.14px;
                    }
        
                    .title {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 1.5;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
        
                    .container-image {
                        display: none;
                    }
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
                            padding: 5px;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin-top: 5px;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 18px;
                                line-height: 27px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
                    
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                        right: 140px;
    
                        .display-image {
                            position: relative;
                            width: 450px ;
                            height:  450px ;
                            transform: scale(1.3);
                        }
                    }
        
                }
    
                .botton-container {
                    margin-top: 60px !important;
                }
            }
        
        }
    
        .conclusion-section {
    
            display: flex;
            width: 100%;
            height: fit-content;
            padding: 64px 20px 64px 20px;
            background-color: #ffffff;
        
            .section-container {
                width: 100%;
                max-width: 1600px;
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    padding-right: 0px;
                    max-width: 600px;
                    width: 100%;
                    margin: auto;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22.14px;
                    }
        
                    .title {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 1.5;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 500;
                        margin-top: 60px;
                        font-size: 18px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
                            padding-bottom: 10px;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin-top: 6px;
                                    margin-bottom: auto;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 18px;
                                line-height: 27px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: none;
        
                    .image-container {
                        height: 547px;
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        width: fit-content;
                        margin-right: auto;
                    }
        
                    .circle-decoration:nth-child(1) {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1; 
                        top: 70px;
                        left: 0px;
                    }
        
                    .circle-decoration:nth-child(2) {
                        width:  70px;
                        height: 70px;
                        position: absolute;
                        bottom: 70px;
                        right: 45px;
                    }
        
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                        height: 100%;
                        width: 750px;
                    }
                }

            }
        
        }
    
        .let-connect-us{
            .container{
                display: flex;
                flex-direction: column;
                .title-container{
                    width: 100%;
                }
     
                .let-connect-title{
                    height: auto;
                  
                    .title{
                        margin: auto;
                        font-size: 28px;
                        text-align: center;
    
                    }
                    width: 100%;
                    display: block;
                }
    
                .button-container{
                    transform: translateY(30px);
                    width: 100%;
                    .button-center{
    
                        margin: auto;
                    }
                }
            
            }
           
        }
        
    }

}