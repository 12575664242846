/* ------------------
- Home Page 
---------------------*/

// - Main Banner ------------------
.main-banner { 

    @extend .section-align;
    background: url(../../background-images/background_01.png) no-repeat center center / cover;
    color: map-get($colors, 'white');
    height: 100vh;

    .title {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 1rem;
        max-width: 70%;

        @include lg {
            font-size: 2.5rem;
        }

        @include md {
            max-width: 100%;
            font-size: 1.8rem;
        }
    }

    @include md {
        height: 100vh;
    }
}

// - API Solution ------------------

.api-solution {
    // height: 100vh;
  

    @include md {
        height: fit-content;
    }

    .container{

        padding: 100px 20px;
 
        .insight-container{

            display: flex;
            flex-direction: row;

            @include md {
                flex-direction: column;
            }
            
      
            .mobile-scroll{
                padding-top: 100px;
                display: flex;
                flex-direction: row;
                overflow-x: auto;

                .scroll-card{
                    border-radius: 6px;
                    padding: 20px;
                    margin: 10px;
                    box-shadow: 0px -1px 11px rgba(126, 126, 126, 0.2);
                }

                .section-text {
                    font-family: Raleway;
                    font-size: 16px;
                }

                .section-head{
                    font-size: 18px;
                    line-height: 1.5;
                }
            }
            
        }

        @include md {
            padding: 64px 20px;
        }
    }

    @extend .section-align;

    .section-title {
        margin-bottom: 75px;

        @include lg {
            margin-bottom: 30px;
        }
    }

    .section-head {
        margin-bottom: 20px;
    }

    .insight {
        margin-bottom: 40px;

        &:last-child {
            margin: 0;
        }
    }

    .api-image {
        width: 757px;

        margin-top: -150px;

        @include md {
            transform: scale(0.9);
            margin-top: 0;
        }
    }
}

// - BIGDATA Solution ------------------
.bigdata-solution {
    @extend .section-align;
    background-color: map-get($colors, 'black');
    color: map-get($colors, 'white');
    position: relative;
    display: flex;
    width: 100%;

    .background-video {
        cursor: pointer;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .container {
        position: relative;
        @include md {
            padding-top: 64px 0px !important;
        }

        .section-text{
            font-size: 18px;
            span{
                font-weight: 600;
            }
        }

        .large-display{

            .bigdata-box{

                margin: 20px;
                border-radius: 6px;
                padding: 20px;

                .case-study{
                    font-size: 16px;
                    line-height: 1;
                    text-transform: uppercase;
                    color: white;
                }
                
                .section-head{
                    font-size: 18px;
                    line-height: 1;
                    color: white ;
                }

            }
        }

        .mobile-scroll{

            padding-top: 100px;
            display: flex;
            flex-direction: column;
            overflow-x: auto;

        
            .scroll-card{
                min-width: 300px;
                border-radius: 8px;
                padding: 20px;
                margin-bottom: 20px;
                background-color: rgba($bg-three, 0.8);

                .section-head{
                    font-size: 18px;
                    line-height: 1.5;
                    color: white !important;
                }

                .section-text{
                    font-size: 16px;

                    span{
                        font-weight: 600;
                    }
                }
            }
        
        }
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .bigdata-box {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($bg-three, 0.8);
    }
}

// - AI Solution ------------------
.ai-solution {

    // background-image: linear-gradient(
    //     155deg,
    //     hsl(168deg 100% 50%) 0%,
    //     hsl(164deg 100% 68%) 0%,
    //     hsl(163deg 100% 75%) 0%,
    //     hsl(161deg 100% 81%) 0%,
    //     hsl(161deg 100% 85%) 2%,
    //     hsl(160deg 100% 90%) 4%,
    //     hsl(159deg 100% 93%) 9%,
    //     hsl(159deg 100% 97%) 18%,
    //     hsl(0deg 0% 100%) 45%
    //   );

    @extend .section-align;

    .container{
        padding: 100px 20px;

        @include md {
            padding: 64px 20px;
        }
    }

    .more-button {
        color: $text-one;

        .star-icon {
            filter: brightness(10%) contrast(90%);
        }
    }

    .image-flex{
        display: flex;
        flex-direction: column;

        @include md {
            display: none;
        }
    }

    .ai-image {
        position: relative;
        right: 140px;

        @include md {
            margin: 20px 0px;
            right: -30px;
            min-width: 220px;
        }
    }

    .label {
        color: map-get($colors, 'black');
        font-size: 7.5rem;
        font-weight: 800;
        opacity: 0.05;

        @include md {
            display: none
        }
    }
    
}

// - Unified Communication Solution ------------------
.unified-solution {
    @extend .section-align;
    background: url(../../images/background-banner-02.png) no-repeat center center / cover;
    color: map-get($colors, 'white');

    .wave-two {
        margin-left: -25px;
        margin-top: 70px;
        max-width: 100px;

        .sound-wave {
            .bar {
                &:nth-child(n+10) {
                    display: none;
                }
            }
        }

        @include md {
            display: none;
        }
    }

    @include md {
        padding: 0;
        min-height: auto;

        .col-md-8 {
            padding: 0;
        }
    }
}

.unified-col {
    background-color: rgba(map-get($colors, 'black'), 0.8);
    padding: 90px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(6.5px);
    border-radius: 6px;

    .section-title{
        color: rgba(14, 14, 14, 0.6);
    }

    .section-sub-title{
        padding-top: 30px;
        font-size: 25px;
        color: rgba(14, 14, 14, 0.6);
    }

    .section-text{
        color: rgba(26, 26, 26, 0.7);
    }

    .communication-logos {
        align-items: center;
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
    }

    .wave-one {
        justify-content: flex-end;
        margin: 50px -90px 50px 0;
    }

    @include md {
        padding: 25px
    }
}

// - 5G Solution ------------------
.fiveG-solution {
    @extend .section-align;
    background: $bg-six;
    color: map-get($colors, 'white');

    .section-text {
        max-width: 70%;

        @include md {
            max-width: 100%;
        }
    }

    .mobile-display{
        padding: 64px 0px;
    }

    .fiveG-image {
        position: relative;
        margin: auto;

        @include md {
            right: 0;
            max-width: 100%;
        }
    }
}

// - WHO ARE WE  ------------------
.whoAreWe-section {
    // @extend .section-align;
    padding-top: 150px;
    padding-bottom: 50px;
    height: fit-content;

    .explore-button {

        &::after {
            background-color: $primary;
        }
    }

    .whoAreWe-image {
        float: left;

        @include md {
            max-width: 100%;
            margin-top: 20px;
        }
    }

    .iso-icons {
        align-items: center;
        display: flex;
        gap: 40px;
        list-style: none;
        margin: 0;
        margin-top: 50px;
        padding: 0;

        @include md {
            justify-content: center;
        }
    }

    .more-links {
        align-items: center;
        display: flex;
        gap: 80px;
        justify-content: flex-end;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        padding-right: 50px;

        @include md {
            flex-direction: column;
            gap: 10px;
            text-align: left;
            margin-top: 20px;
        }
    }

    @include md {
        padding: 0px;
    }


}

// - TEAM Count ------------------
.team-count {

   margin-bottom: 60px;

    .container{
        margin: auto;
        background-color: $bg-five;
        @include md {
            margin: 0px 0px;
            padding: 0px 0px !important;
        }
    }

    .count-list {
        align-items: center;
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding-bottom: 25px;

        li{
            text-align: center;

            // @include md {
            //    margin-left: 20px;
            //    margin-right: 20px;
            // }

            .value {
                color: $tertiary;
                display: block;
                font-size: 4rem;
                font-weight: 600;
            }

            .label {
                display: block;
                font-size: 1.25rem;
                max-width: 200px;
            }
        }

        @include md {
            background-color: inherit;
            flex-direction: column;
            padding: 0px 20px;

            li {
                display: flex;
                flex-direction: column;
                background-color: $bg-five;
                text-align: center;
                gap: 20px;
                align-items: center;
                width: 100%;
                margin: 20px;

                .label {
                    flex: 0.7;
                    max-width: auto;
                }

                .value {
                    flex: 0.3;
                    font-size: 2.5rem;
                    text-align: right;
                }
            }
        }
    }

    @include md {
        background-color: inherit;
    }

}

// - Our Clients ------------------
.our-clients {

    padding: 0px 20px;

    .container{
        padding: 40px;
        @include md {
            padding-top: 0px;
            padding-bottom: 0px;
            padding: 0px;
        }
    }

    .head {
        text-transform: uppercase;
    }

    .wave-client {
        display: flex;
        width: 100%;

        .wave-animation {
            width: 50%;

            .sound-wave {
                height: 35px;
                opacity: 0.5;
                width: 100%;

                .bar {
                    animation-name: soundClient;
                    background-color: $text-three;
                    height: 35px;
                }
            }
        }
    }

    .clients-list {

        display: flex;
        justify-content: space-evenly;
        list-style: none;
        margin-bottom: 50px;
        margin-top: 70px;
        padding: 0;
        overflow-x: auto;

        @include md {
            margin: 20px auto;
            flex-direction: row;

            gap: 10px;

            li {
                margin: auto;

                img {
                    width: 100px;
                    max-width: 100%;
                }
            }
        }
    }

    @include md {
        padding: 50px 15px;
    }
}

// - Our Product ------------------
.our-product-home {
    @extend .section-align;
    border-top: 1px map-get($borders, 'solid') $border-color;
    padding-bottom: 150px;

    .button-container{
        display: flex;
        position: relative;
        flex-direction: row;
        padding-bottom: 60px;

        div{
            padding-top: auto;
            padding-bottom: auto;
        }

        .sub-title{
            margin-left: 0px;
            font-family: Raleway;
            font-size: 50px;
            font-weight: 600;
            line-height: 55px;
            letter-spacing: 0em;
            text-align: left;

            @include md {
                padding-bottom: 20px;
                font-size: 30px;
            }
        }

        @include md {
            flex-direction: column;
        }

    }

    .explore{
        padding-top: 90px;
        font-family: Raleway;
        font-size: 50px;
        font-weight: 300;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;

        @include md {
            padding-top: 0px;
            font-size: 30px;
        }
    }



    @include md {
        padding: 0px 0px;
    }


}

.product-list {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        cursor: pointer;
        height: calc(100vh - 200px);
        position: relative;
        transition: all 1s ease;
        // width: 25%;
        width: 50%;

        * {
            transition: all 1s ease;
        }

        &.active {
            transition: all 1s ease;
            width: 50%;
            
            .content {
                padding-top: 60px;
                position: absolute;
                -webkit-backdrop-filter: blur(0px);
                backdrop-filter: blur(0px);
                background-color: transparent;

                .name{
                    padding-top: 20px;
                }

                @include md {
                    margin-top: -40px;
                    padding-left: 20px;
                    padding-top: 0px;
                    position: absolute;
                    height: 40px;
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);
                    background-color: rgba($bg-three, 0.8);
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;

                    .name {
                        margin-top: -10px;
                        color: rgb(255, 255, 255);
                    }
    
                    .text {
                        height: 0;
                        opacity: 0;
                    }
                }
            }

            .image {
                height: calc(100% - 170px);

                @include md {
                    height: calc(100% - 200px);
                }

                img {
                    box-shadow: 0 4px 25px rgba(map-get($colors, 'black'), 0.2);
                }
            }



        }

        &:not(.active) {

            .content {
                position: absolute;

                .name {
                    margin-left: 10px;
                    color: rgb(255, 255, 255);
                }

                .text {
                    height: 0;
                    opacity: 0;
                }
            }
        }
    }

    .image {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .content {
        margin-top: -40px;
        padding-left: 20px;
        position: absolute;
        height: 40px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($bg-three, 0.8);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .name {
            padding-top: 10px;
            font-weight: 700;
            text-transform: uppercase;
            color: black;
        }

        .text {
            font-size: 1.25rem;
            height: auto;
            margin: 0;
            opacity: 1;
            overflow: hidden;
            color: black;

            @include md {
                padding-bottom: 30px;
            }

        }

        @include md {
            padding-left: 10px;
        }

    }

    @include md {
        flex-direction: column;


        li {
            width: 100%;
            height: 150px;

            &.active {
                width: 100%;
                height: auto;
            }
        }
    }

}

// - ProSEED Learning ------------------
.our-learning {
    @extend .section-align;
    background-color: $bg-one;
    color: map-get($colors , 'white');

    .learning-logo {
        margin-bottom: 50px;
        background-image: url('../../images/learning-logo.svg');
        background-repeat: no-repeat;
        width: 430px;
        height: 100px;

        @include md {
            margin-top: 25px;
            background-image: url('../../images/learning-medium.svg');
            width: 300px;
            height: 130px;
        }
    }

    @include md {
        min-height: auto;
    }
}

.learning-slider-col {
    display: flex;
    gap: 25px;
    margin-left: -100px;
    height: 100vh;
    overflow: hidden;

    @include md {
        display: none;
    }
}

.learning-slider {
    width: 300px;
    display: inline-flex;

    &.two {
        transform: rotate(-180deg);

        .item {
            transform: rotate(180deg);
        }
    }

    // &.one {
    // }

    .slick-list {
        overflow: visible;
    }

    .item {
        margin: 10px 0;
        cursor: pointer;
        position: relative;
        

        &:hover {
            z-index: 99;

            .info {
                opacity: 1;
                max-width: 100vw;
                max-height: 100vh;
                min-width: 300px;
            }

            .image {
                background-color: $text-one;

                img {
                    opacity: 0.5;
                }
            }
        }

        .image {
            display: inline-flex;
            height: 300px;
            width: 300px;
            overflow: auto;
            border-radius: 50%;
            background-color: map-get($colors, 'black');

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.3;
            }

            @include md {
                width: 100%;
            }
        }

        .left-info{
            left: 30px;
            top: 180px;
        }

        .right-info{
            left: -30px;
            top: 180px;
        }

        .info {
            position: absolute;
            max-width: 0;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            z-index: 2;
            padding-bottom: 10px;
            font-size: 14px;
            line-height: 1.3;
            font-style: italic;
            display: flex;
            flex-direction: column;

            .name {
                padding-top: 10px;
                padding-right: 30px;
                white-space: nowrap;
                display: block;
                font-weight: 600;
                font-size: 16px;
                margin-left: auto;
            }

            span {
                display: block;
                font-size: 1.125rem;
            }
        }
    }

}

// - OUR Collaboration ------------------
.our-collaborations {
    @extend .section-align;

    .collab-list {
        display: flex;
        gap: 30px;
        list-style: none;
        margin-bottom: 0;
        margin-top: 30px;
        padding: 0;
    }

    .bg-collaborations {
        background: url(../../images/background-banner-03.png) no-repeat center center / cover;
        padding: 75px 0 0;
        width: 100%;

        @include md {
            padding: 25px 0;
            // background:none;
        }
    }

    .more-button {
        color: $text-one;

        .star-icon {
            filter: brightness(10%) contrast(90%);
        }
    }

    .testimonial {
        background-color: map-get($colors, 'white');
        border-radius: 4px;
        box-shadow: 0px -1px 11px rgba($text-three, 0.2);
        clear: both;
        margin-bottom: 50px;
        max-width: 480px;
        padding: 36px;
        position: relative;
    
        &:first-child {
            float: right;
        }
    
        .icon {
            position: absolute;
            right: 47px;
            top: -34px;
    
            @include md {
                width: 50px;
            }
        }
    
        .head {
            display: flex;
            gap: 26px;
            margin-bottom: 20px;
            margin-left: -75px;
    
            .user {
                position: relative;
                height: 80px;
                width: 80px;
    
                &::before {
                    border: 1px solid $text-three;
                    border-radius: 50%;
                    content: '';
                    height: 100%;
                    left: -8px;
                    position: absolute;
                    top: 8px;
                    width: 100%;
                }
    
                img {
                    position: relative;
                }
            }
    
            .info {
                .name {
                    font-size: 1.5rem;
    
                    @include md {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }
                }
    
                .department {
                    font-size: 1.25rem;
    
                    @include md {
                        font-size: 1rem;
                    }
                }
            }
    
            @include md {
                margin-left: 0;
            }
        }
    
        .message {
            font-size: 1.25rem;
            font-style: italic;
    
            @include md {
                font-size: 1rem;
            }
        }
    
        @include md {
            margin-top: 25px;
            padding: 25px;
        }
    }

    @include md {
        padding: 0;

        .mobile-scroll{
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            padding-top: 20px;
        
            .testimonial {
                background-color: map-get($colors, 'white');
                border-radius: 4px;
                box-shadow: 0px -1px 11px rgba($text-three, 0.2);
                clear: both;
                margin-right: 20px;
                max-width: 480px;
                min-width: 252px;
                position: relative;
            
                &:first-child {
                    float: right;
                }
            
                .icon {
                    position: absolute;
                    right: 47px;
                    top: -34px;
            
                    @include md {
                        width: 50px;
                    }
                }
            
                .head {
                    display: flex;
                    gap: 26px;
                    margin-bottom: 20px;
                    margin-left: -75px;
            
                    .user {
                        position: relative;
                        height: 80px;
                        width: 80px;
            
                        &::before {
                            border: 1px solid $text-three;
                            border-radius: 50%;
                            content: '';
                            height: 100%;
                            left: -8px;
                            position: absolute;
                            top: 8px;
                            width: 100%;
                        }
            
                        img {
                            position: relative;
                        }
                    }
            
                    .info {
                        .name {
                            font-size: 1.5rem;
            
                            @include md {
                                font-size: 1.25rem;
                                font-weight: 600;
                            }
                        }
            
                        .department {
                            font-size: 1.25rem;
            
                            @include md {
                                font-size: 1rem;
                            }
                        }
                    }
            
                    @include md {
                        margin-left: 0;
                    }
                }
            
                .message {
                    font-size: 16px ;
                    line-height: 24px ;
                    margin-left: 30px ;
                    font-style: italic;
            
                    @include md {
                        font-size: 1rem;
                        margin-left: 0px;
                    }
                }
            
                @include md {
                    margin-top: 25px;
                    padding: 25px;
                }
            }
        
        }
    }

}

// - Let's Connect ------------------
.let-connect {
    background: $bg-seven url(../../images/map.png) no-repeat center center;
    // background-color: bisque;
    height: 650px;
    padding: 100px 0 100px;
    text-align: center;

    .title{

        display: none;
        visibility: hidden;

        @include md{
            display: flex;
            visibility: visible;
            color: white;
            text-align: left;
            font-size: 30px;
            font-weight: 600;
            padding-bottom: 20px;
        }

    }


    .more-button {
        font-size: 1.5rem;

        &:hover {
            .star-icon {
                left: 8px;
                transition: all 0.5s ease-in;
            }
        }

        .star-icon {
            left: 0;
            transition: all 0.5s ease-in;
        }
    }

    .map-points {
        display: flex;
        flex-direction: column;
        height: 236px;
        margin: 0 auto 100px;
        max-width: 342px;
        position: relative;

        .point {
            align-items: center;
            background-color: $bg-eight;
            border: 2px map-get($borders, 'solid') $border-color;
            border-radius: 50%;
            cursor: pointer;
            display: inline-flex;
            height: 40px;
            justify-content: center;
            position: absolute;
            transition: all 0.3s ease-in;
            width: 40px;
            // , &.active 
            
            &:hover, &.active {
                background-color: $primary;
                border-color: $primary;
                transition: all 0.3s ease-in;

                &::after {
                    opacity: 1;
                    transition: all 0.5s ease;
                }

                .address-col {
                    max-height: 100vh;
                    opacity: 1;
                    padding: 35px;
                    transition: all 0.5s ease;
                }

                @include md {
                    background-color: transparent;
                    .address-col {
                        padding: 15px;
                    }
                }
            }

            &::before {
                background-color: $border-color;
                border-radius: 50%;
                content: '';
                height: 5px;
                width: 5px;
            }

            &::after {
                background-color: $primary;
                content: '';
                height: 3px;
                opacity: 0;
                position: absolute;
                transition: all 0.5s ease;
                width: 160px;
            }

            &:first-child {
                left: 101px;
                top: 98px;
    
                &::after {
                    right: 18px;
                    top: 57px;
                    transform: rotate(-27deg);
                }

                .address-col {
                    right: 167px;
                    top: 90px;
                }
            }

            // &:nth-child(2) {
            //     left: 278px;
            //     top: 215px;

            //     &::after {
            //         left: 3px;
            //         top: -44px;
            //         transform: rotate(137deg);
            //     }

            //     .address-col {
            //         bottom: 59px;
            //         left: 140px;
            //     }
            // }

            &:nth-child(2) {
                left: 258px;
                top: 198px;

                &::after {
                    left: 3px;
                    top: -44px;
                    transform: rotate(137deg);
                }

                .address-col {
                    bottom: 59px;
                    left: 140px;
                }
            }


            &:last-child {
                bottom: -60px;
                right: -52px;
            

                &::after {
                    left: 10px;
                    top: 50px;
                    transform: rotate(46deg);
                    width: 84px;
                }

                .address-col {
                    left: 80px;
                    top: -160px;
                    
                    .address{
                        padding-bottom: 20px;
                        padding-top: 10px;
                    }
                }
            }

            @include md {
                background: none;
                border: none;
                border-radius: 0;
                height: auto;
                position: initial;
                max-width: 100%;
                width: 100%;;

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .address-col {
            background-color: rgba($bg-one, 0.5);
            border: 2px map-get($borders, 'solid') $primary;
            border-radius: 8px;
            color: map-get($colors, 'white');
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            padding: 0;
            pointer-events: none;
            position: absolute;
            text-align: left;
            transition: all 0.5s ease-in;

            .name {
                display: block;
                font-size: 1.5rem;
                margin-bottom: 15px;
                white-space: nowrap;

                @include md {
                    font-size: 1.25rem;
                    margin-bottom: 5px;
                }
            }

            .head-office{
                font-family: Raleway;
                color: white;
                font-size: 16px;
                font-weight: bold;
                padding-top: 20px;
                padding-bottom: 10px;
            }

            .address {
                font-family: Raleway;
                display: block;
                font-size: 1.125rem;
                min-width: 250px;

                @include md {
                    font-size: 1rem;
                }
            }

            @include md {
                position: initial;
                opacity: 1;
                max-height: none;
                padding: 15px;
                margin: 10px auto;
                border-width: 1px;
                width: 100%;
            }
        }

        @include md {
            height: auto;
            padding-bottom: 30px;
            margin: 0 auto;
            max-width: 100%;
            width: auto
        }

    }

    .letconnect-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;



        @include md {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .botton-container{
                margin-left: 0px!important;
            }
            
        }
    }

    @include md {
        display: flex;
        visibility: visible;
        height: auto;
        clear: both;
        background-size: cover;
        padding: 25px 0;
    }

}

// .learning-slider .item .info span