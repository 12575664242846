@use '../../../assets/sass/others/variables' as theme;

@use "sass:map";

$color_1: rgb(243, 104, 74);
$color_2: black;
$color_3: rgba(0, 0, 0, 0.6);

.api-gateway{
    
    .introduction-section {
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            margin: auto;
            width: 100%;
            height: 547px;
            display: flex;
            max-width: 1600px;
            flex-direction: row;
            position: relative;
    
    
            .container-content {
                padding-left: 120px;
                max-width: 720px;
                width: 100%;
                margin: auto;

                .container-image {
                    display: none;
                }
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {

                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }

                    // font-size: 50px;
                    // font-weight: 300;
                    // line-height: 65px;
                    // color: rgb(90, 83, 107);
    
                    // span {
                    //     color: rgb(243, 104, 74);
                    // }

                }
    
                .description {
                    // font-family: Raleway;
                    // font-weight: 400;
                    margin-top: 60px;
                    // font-size: 18px;
                    // line-height: 130%;
                    // color: rgba(0, 0, 0, 0.6);
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .description-second {
                    font-family: Raleway;
                    font-weight: 400;
                    margin-top: 10px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
    
    
            }
    
            .container-image {
                height: auto;
                width: 100%;
                display: flex;

                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                    height: 500px;
                    width: auto;
                    
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 70px;
                        right: 0px;
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
    
                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        left: -25px;
                        margin-top: auto;
                        bottom: 25px;
                        z-index: 0;
                    }
    
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        height: auto;
                        width: auto;
                    }
                }
            }
    
        }
    }
    
    .features-section {
        display: flex;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 200px;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            width: 100%;
            margin: auto;
            max-width: 1600px;
            height: 547px;
            display: flex;
            flex-direction: row;
            position: relative;
    
            .container-image {
                height: 547px;
                display: flex;
                flex-direction: column;
                position: relative;
    
                .circle-decoration {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                }
    
                .display-image {
                    position: relative;
                    clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 100%;
                    width: 750px;
                }
            }
    
            .container-content {
                margin-left: 20px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
            }
    
            .card-container {
                position: absolute;
                bottom: -150px;
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: row;
    
                .card {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 400px;
                    height: 262px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 20px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -60px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Raleway;
                        font-weight: 600;
                        font-size: 24px;
                        text-align: left;
                        color: rgb(58, 49, 76);
                        padding-top: 40px;
                    }
                }
    
                // .card:nth-child(1){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(2){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(3){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
    
    
            }
        }
    }
    
    .api-gateway-section {
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: white;
        display: flex;
        flex-direction: column;
    
        .section-container {
            width: 100%;
            margin: auto;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            position: relative;
    
            .container-head {
                margin: auto;
                width: 1200px;
    
                .description {
                    font-family: Raleway;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.6);
    
                }
    
                .title {
                    font-family: Raleway;
                    font-size: 50px;
                    font-weight: 300;
                    line-height: 65px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: rgba(0, 0, 0, 1);
    
                    span {
                        color: rgba(243, 104, 74, 1);
                    }
                }
            }
    
            .container-body {
                display: flex;
                flex-direction: row;
                width: 1200px;
                margin: 100px auto auto;
    
                .card {
                    width: 378px;
                    height: 260px;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    justify-content: center;
                    box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 19px 0px;
                    outline: none;
                    border: 1px solid rgba(249, 246, 245, 1);
    
                    .card-icon {
                        width: 50px;
                        height: 50px;
                        margin: auto;
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 42px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(67, 67, 67, 1);
                    }
    
                    .description {
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 27px;
                        color: $color_3;
                        text-align: center;
                    }
    
                }
    
                .arrow-container {
                    height: 260px;
                    display: flex;
                    flex-direction: column;
    
                    .arrow-image {
                        height: 36px;
                        width: 48px;
                        margin: auto;
                    }
                }
            }
    
            .container-footer {
                width: 1200px;
                text-align: center;
                margin: 80px auto auto;
            }
        }
    }
    
    .concusion-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 200px;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            margin: auto;
            display: flex;
            width: 100%;
            max-width: 1600px;
            height: 547px;
            display: none;
            flex-direction: row;
            position: relative;

    
            .container-image {
                height: 547px;
                display: flex;
                flex-direction: row;
                position: relative;
    
                .circle-decoration {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                }
    
                .display-image {
                    position: relative;
                    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                    height: 547px;
                    width: 750px;
                }
    
                .inner-card {
                    font-family: Raleway;
                    position: relative;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    z-index: 1;
                    line-height: 27px;
                    width: 500px;
                    height: fit-content;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: auto;
                    margin-top: -120px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 40px 20px;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -70px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Raleway;
                        padding-top: 24px;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
    
                    .description {
                        font-family: Raleway;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }
            }
    
            .container-content {
                margin-left: 20px;
                max-width: 460px;

                .mobile-card-container{
                    display: none;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    font-family: Raleway;
                    text-indent: 0px;
                    font-weight: 400;
                    margin-top: 30px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
            
        }
    
        .section-container:nth-child(2) {
            margin-top: 0px;
            display: flex;

            .title {
                font-size: 50px;
                font-weight: 500;
                line-height: 65px;
                color: rgb(90, 83, 107);

                span {
                    font-weight: 600;
                    color: rgb(243, 104, 74);
                }
            }
            
            .container-image{
                display: flex;
                flex-direction: row;
                width: 100%;
    
                .inner-card {
                    margin-left: -100px;
                    height: fit-content;
                }
    
                .image-container{
                    margin-left: auto;
                    position: relative;
        
                    .display-image {
                        position: relative;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        height: 547px;
                        width: 750px;
                        margin-left: auto;
                    }
            
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        right: 0px;
                        -webkit-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
                }
            }
    
            .container-content{
                margin-left: 120px;
            }
    
    
        }
    }
}


@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .api-gateway{

        .introduction-section {
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;

        
            .section-container {
                margin: auto;
                width: 100%;
                height:  350px;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'lg');
                flex-direction: row;
                position: relative;

                .container-content {
                    padding-left: 0px;
                    min-width: 440px;
                    width: 100%;
                    margin: auto;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 130%;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 60px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .description-second {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
        
                }
        
                .container-image {
                    height: auto;
                    display: flex;
        
                    .image-container {
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
        
                        .circle-decoration {
                            width: 25px;
                            height: 50px;
                            position: absolute;
                            z-index: 1;
                            top: 50px;
                            right: 0px;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                        }
        
                        .circle-decoration:nth-child(2) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            z-index: 0;
                            left: -20px;
                            bottom: 10px;
                        }
        
                        .display-image {
                            width: 450px ;
                            height:  350px ;
                            position: relative;
                            margin-left: auto;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        }
                    }
                }
        
            }
        }

        .features-section {
            display: flex;
            width: 100%;
            padding-top: 80px;
            padding-bottom: 200px;
            background-color: rgb(249, 249, 249);
        
            .section-container {
                width: 100%;
                height:  350px;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                position: relative;
        
                .container-image {
                    height: 547px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
        
                    .circle-decoration {
                        width: 25px;
                        height: 50px;
                        position: absolute;
                        z-index: 1;
                        top: 50px;
                    }
        
                    .display-image {
                        width: 450px ;
                        height:  350px ;
                        position: relative;
                        clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    }
                }
        
                .container-content {
                    margin-left: 20px;
                    min-width: 540px;
                    width: 100%;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20.14px;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 62px;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
                }
        
                .card-container {
                    position: absolute;
                    bottom: -180px;
                    display: flex;
                    flex-direction: row;
        
                    .card {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        text-align: left;
                        line-height: 25px;
                        width: 300px;
                        height: 230px;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 15px;
                        margin-bottom: 30px;
                        margin-top: 30px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        outline: none;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 10px;
                            position: absolute;
                            margin-top: -45px;
                            width: 50px;
                            height: 50px;
                        }
        
                        .title {
                            font-family: Raleway;
                            font-weight: 600;
                            font-size: 22px;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 25px;
                        }
                    }
        
                    // .card:nth-child(1){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(2){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
                    // .card:nth-child(3){
                    //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                    // }
        
        
                }
            }
        }
        
        .api-gateway-section {
            width: 100%;
            padding-top: 80px;
            padding-bottom: 80px;
            background-color: white;
            display: flex;
            flex-direction: column;
        
            .section-container {
                width: 100%;
                margin: auto;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: column;
                position: relative;
        
                .container-head {
                    margin: auto;
                    width: 100%;
        
                    .description {
                        font-family: Raleway;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.6);
        
                    }
        
                    .title {
                        font-family: Raleway;
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 63px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(0, 0, 0, 1);
        
                        span {
                            color: rgba(243, 104, 74, 1);
                        }
                    }
                }
        
                .container-body {
                    margin: auto;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin: 100px auto auto;
        
                    .card {
                        margin: auto;
                        width: 300px;
                        height: 240px;
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        border-radius: 6px;
                        padding: 20px;
                        justify-content: center;
                        box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 19px 0px;
                        outline: none;
                        border: 1px solid rgba(249, 246, 245, 1);
        
                        .card-icon {
                            width: 40px;
                            height: 40px;
                            margin: auto;
                        }
        
                        .title {
                            font-family: Raleway;
                            font-size: 30px;
                            font-weight: 600;
                            line-height: 40px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: rgba(67, 67, 67, 1);
                        }
        
                        .description {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 25px;
                            color: $color_3;
                            text-align: center;
                        }
        
                    }
        
                    .arrow-container {
                        height: 240px;
                        display: flex;
                        flex-direction: column;
        
                        .arrow-image {
                            height: 34px;
                            width: 46px;
                            margin: auto;
                        }
                    }
                }
        
                .container-footer {
                    width: 100%;
                    text-align: center;
                    margin: 80px auto auto;
                }
            }
        }
        
        .concusion-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 180px;
            background-color: rgb(249, 249, 249);

            .main-contrainer{
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'lg');
                margin: auto ;

                .section-container {
                    display: flex;
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    position: relative;
            
                    .container-image {
                        height:  350px;
                        display: flex;
                        flex-direction: row;
                        position: relative;
            
                        .circle-decoration {
                            width: 25px;
                            height: 50px;
                            position: absolute;
                            z-index: 1;
                            top: 50px;
                        }
            
                        .display-image {
                            width: 450px ;
                            height:  350px ;
                            position: relative;
                            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                        }
            
                        .inner-card {
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 16px;
                            text-align: left;
                            z-index: 1;
                            line-height: 25px;
                            width: 400px;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: -80px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 30px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 10px;
                                position: absolute;
                                margin-top: -60px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 20px;
                                font-size: 22px;
                                font-weight: 500;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
                    }
            
                    .container-content {
                        // margin-left: 20px;
                        max-width: 500px;

                        .mobile-card-container{
                            display: none;
                        }
            
                        .title {
                            font-weight: 300;
                            font-size: 50px;
                            line-height: 65px;
                            color: '#3A314C';
                        }
            
                        .description {
                            font-family: Raleway;
                            text-indent: 0px;
                            font-weight: 400;
                            margin-top: 60px;
                            font-size: 16px;
                            line-height: 130%;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            
                .section-container:nth-child(2) {
                    margin-top: 80px;
            
                    .container-image{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
            
                        .inner-card {
                            margin-left: -80px;
                            height: fit-content;
                        }
            
                        .image-container{
                            margin-left: auto;
                            position: relative;
                
                            .display-image {
                                position: relative;
                                clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                                width: 450px ;
                                height:  350px ;
                                margin-left: auto;
                            }
                    
                            .circle-decoration {
                                width: 25px;
                                height: 50px;
                                position: absolute;
                                z-index: 1;
                                right: 0px;
                                -webkit-transform: scaleX(-1);
                                transform: scaleX(-1);
                            }
                        }
                    }
            
                    .container-content{
                        
                        margin-left: 0px;
                        max-width: 440px;
                        width: 100%;

                    }
            
            
                }

            }
        

        }
    }
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .api-gateway{

    .introduction-section {

        display: flex;
        width: 100%;
        padding: 64px 20px;
        background-color: map.get(theme.$colors, "white");

        .section-container {
            width: 100%;
            max-width: map-get(theme.$brakpoint, 'sm');
            height: max-content;
            display: flex;
            flex-direction: row;
            margin: auto;

            .container-content {
                margin-left: 0px;
                padding-left: 0px;
                min-width: auto;

                .label {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }

                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgb(90, 83, 107);

                    span {
                        color: rgb(243, 104, 74);
                    }
                }

                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.6);
                }

                .container-image {
                    margin-left: auto;
                    width: 100%;
                    display: flex;

                    .image-container {
                        height: 237px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                    }

                    .circle-decoration:nth-child(1) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 150px;
                        right: -35px;
                        display: none;
                    }

                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        bottom: 70px;
                        left: -15px;
                        display: none;
                    }

                    .display-image {
                        width: 400px ;
                        height:  100% ;
                        position: relative;
                        margin-left: 0px;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    }
                }
            }

            .container-image {
                display: none;
            }
        }

    }

    .features-section {
        display: flex;
        flex-direction: column;
        padding-top: 64px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: fit-content;
        background-color: rgb(249, 249, 249);
    
        .section-container {
            width: 100%;
            margin: auto;
            height: 100%;
            max-width: map-get(theme.$brakpoint, 'sm');
            display: flex;
            flex-direction: column;
            position: relative;
    
            .container-image {
                display: none;
                height: 0px;
            }
    
            .container-content {
                margin-left: 0px;
                max-width: 600px;
                width: 100%;
                height: fit-content;
    
                .label {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.16em;
                    text-align: left;
                    
                }
    
                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
            }
    
            .card-container {
                position: relative;
                display: flex;
                margin-top: -100px;
                flex-direction: column;
    
                .card {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    
                    width: 100%;
                    height: fit-content;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 0px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 5px;
                        position: absolute;
                        margin-top: -60px;
                        width: 70px;
                        height: 70px;
                        transform: scale(0.8);
                    }
    
                    .title {
                        font-family: Raleway;
                        font-size: 19px;
                        font-weight: 600;
                        line-height: 23px;
                        letter-spacing: 0em;
                        text-align: left;
                        padding-top: 35px;
                    }
                }
    
                // .card:nth-child(1){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(2){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
                // .card:nth-child(3){
                //     font-family: Raleway; font-weight: 400; font-size: 18px; text-align: left; line-height: 27px; width: 400px; height: 262px; color: rgba(58, 49, 76, 0.8); margin-left: 20px; margin-bottom: 30px; margin-top: 30px; background-color: white; border-radius: 6px; padding: 20px;
                // }
    
    
            }
        }
    }

    .api-gateway-section {
        width: 100%;
        padding: 64px 20px;
        background-color: white;
        display: flex;
        flex-direction: row;
    
        .section-container {
            width: 100%;
            margin: auto;
            max-width: map-get(theme.$brakpoint, 'sm');
            display: flex;
            flex-direction: column;
            position: relative;
    
            .container-head {
                margin: auto;
                width: 100%;
    
                .description {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.6);

                }
    
                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(0, 0, 0, 1);
    
                    span {
                        color: rgba(243, 104, 74, 1);
                    }
                }
            }
    
            .container-body {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 35px;
                // margin: 100px auto auto;
    
                .card {
                    width: 100%;
                    height: fit-content;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 6px;
                    padding: 20px;
                    justify-content: center;
                    box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 19px 0px;
                    outline: none;
                    border: 1px solid rgba(249, 246, 245, 1);
    
                    .card-icon {
                        width: 50px;
                        height: 50px;
                        margin: auto;
                    }
    
                    .title {
                        font-family: Raleway;
                        margin-top: 10px;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 26px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: rgba(67, 67, 67, 1);
                    }
    
                    .description {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: $color_3;
                    }
    
                }
    
                .arrow-container {
                    display: flex;
                    height: auto;
                    padding: 10px 0px;
                    width: 100%;
                    flex-direction: row;
    
                    .arrow-image {
                        height: 36px;
                        width: 48px;
                        margin: auto;
                        transform: rotate(90deg);
                    }
                }
            }
    
            .container-footer {
                font-family: Raleway;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                width: 100%;
                margin: 80px auto auto;
            }
        }
    }

    .concusion-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: rgb(249, 249, 249);

        .main-contrainer{
            margin: auto;
            max-width: map-get(theme.$brakpoint, 'sm');;

            .section-container {
                display: flex;
                margin: auto;
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                position: relative;
        
                .container-image {
                    display: none;
                    height: 547px;
                    flex-direction: row;
                    position: relative;
        
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 70px;
                    }
        
                    .display-image {
                        position: relative;
                        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
                        height: 547px;
                        width: 750px;
                    }
        
                    .inner-card {
                        font-family: Raleway;
                        position: relative;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: left;
                        z-index: 1;
                        line-height: 27px;
                        width: 500px;
                        height: fit-content;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: auto;
                        margin-top: -120px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 40px 20px;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 15px;
                            position: absolute;
                            margin-top: -70px;
                            width: 70px;
                            height: 70px;
                        }
        
                        .title {
                            font-family: Raleway;
                            padding-top: 24px;
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 30px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
        
                        .description {
                            font-family: Raleway;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
                }
        
                .container-content {
                    margin-left: 0px;
                    max-width: 600px;
                    height: fit-content;
                    // margin: auto;
        
                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 400;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        span{
                            color: rgba(252, 86, 36, 1);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .mobile-card-container{
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        height: 300px;
                        overflow: hidden;

                        .background-image{
                            position: absolute;
                            width: 100%;
                            height: 300px;
                        }

                        .inner-card {
                            margin: auto;
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 80%;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: 50px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 0px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 15px;
                                position: absolute;
                                margin-top: -20px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 60px;
                                font-size: 22px;
                                font-weight: 500;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                margin-top: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        
            .section-container:nth-child(2) {
                margin-top: 0px;
                margin: auto;
        
                .container-image{
                    display: none;
                    flex-direction: row;
                    width: 100%;
        
                    .inner-card {
                        margin-left: -100px;
                        height: fit-content;
                    }
        
                    .image-container{
                        margin-left: auto;
                        position: relative;
            
                        .display-image {
                            position: relative;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                            height: 547px;
                            width: 750px;
                            margin-left: auto;
                        }
                
                        .circle-decoration {
                            width: 35px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            right: 0px;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                        }
                    }
                }
        
                .container-content{
                    margin-left: 0px;
                    max-width: 100%;
                    width: 100%;
                }
        
            }
        }
    

    }
    
    }

}