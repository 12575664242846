
.carrier-list-section{

  .carrier-list-head {
    margin-top: 120px;
    width: 70%;
  }

}


.carrier-form-section{

  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;

  .form-container-row{

    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 150px;
    

    .contact-us-form {
      padding-top: 20px;
      padding-bottom: 40px;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .botton-container{
        translate: 0px 20px;
      }
          
        .input {
        width: 150%;
        padding-left: 5px;
        margin-top: 20px;
        border-style: solid;
        border-radius: 6px;
        border-color: #B1B1B1;
        border-width: 1px;
        height: 45px;
        -webkit-appearance: none;
        appearance: none;
        }

          .upload-resume-text{
            margin-top: 20px;
          }

          .file-upload{
            margin-top: 20px;
          }


          .select-position{
              height: 45px;
              margin-top: 20px;
              cursor: pointer;
              padding-left: 5px;
              border-radius: 6px;
              border-color: #B1B1B1;

              .option{
                background-color: rgb(141, 81, 81);
              }
          }

          .submit-button-container{
            margin-top: 40px;
            margin-left: 25px;
          }
          
          .contact-us-form input:focus {
          outline: none;
          }

          .error{
            color: #F3684A;
          }


      .success-message-container{
              width: 100%;
              height: 300px;
              display: flex;

              div{
                  margin: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: left;

                  .success-message{

                      font-family: Raleway;
                      font-size: 50px;
                      font-weight: 300;
                      line-height: 65px;
                      letter-spacing: 0em;
                      text-align: center;
                      white-space: nowrap;

                      span{
                          font-family: Raleway;
                          font-size: 50px;
                          font-weight: 500;
                          line-height: 65px;
                          letter-spacing: 0em;
                          text-align: center;
                          color: #F3684A;
                      }

                  }

                  .short-message{
                      font-family: Raleway;
                      font-size: 24px;
                      line-height: 31px;
                      letter-spacing: 0em;
                      text-align: left;

                  }
              }

      }

      }

    .carrer-picture{
      width: 50%;
      height: 100%;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img{
        height: 600px;
        margin: auto;
      }
    }
    
  }

}


.carrier-list-title {
    font-family: 'Raleway';
    font-size: 50px;
    font-weight: 500;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
  }
  
  .carrier-list-title>span {
    color: #F3684A;
  }
  
  .carrier-list-sub-title {
    font-family: 'Raleway';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .carrier-list-item {
    
    display: flex;
    padding: 90px 0px;
    font-family: 'Raleway';
    font-style: normal;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: #EEEEEE;
    flex-direction: row;

    div:nth-child(1) {
        width:fit-content;
      }

    div:nth-child(2) {
        width:40%;
        margin: auto;
        .carrier-apply-now{
          width: fit-content;
          margin: auto;
          display: flex;
          flex-direction: row;
        }
      }
  }

  .carrier-list-container {
    display: flex;
    flex-direction: column;
  }

  .carrier-job-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.3;
    align-items: center;
    color: #3A314C;
  }
  
  .carrier-job-description {
    font-family: 'Raleway';
    font-style: normal;
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #7E7E7E;
  }

  @media only screen and (max-width: 960px){

    .carrier-list-section{

      .carrier-list-head {
        margin-top: 0px;
        width: 70%;
      }
    
    }
    
    .carrier-list-title {
        font-family: 'Raleway';
        font-size: 24px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0em;
        text-align: left;
      }

      .carrier-list-sub-title {
        font-family: 'Raleway';
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0em;
        text-align: left;
      }

      .carrier-list-head {
        margin-top: 64px;
        width: 70%;
      }

      .carrier-list-item {
        padding: 64px 0px;
        display: flex;
        font-family: 'Raleway';
        font-style: normal;
        width: 100%;
        border-bottom-style: solid;
        border-bottom-color: #EEEEEE;
        flex-direction: column;

        div:nth-child(1) {
            width: 100%;
          }
    
        div:nth-child(2) {
            width: 100%;
            margin-left: 0px;
            .carrier-apply-now{
              width: fit-content;
              margin: 0;
              display: flex;
              flex-direction: row;

            }
          }

        .carrier-job-title {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 1.5;
            color: #3A314C;
          }
          
          .carrier-job-description {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.5;
            display: flex;
            align-items: center;
            color: #7E7E7E;
          }
      }

      .carrier-form-section{

        display: flex;
        flex-direction: row;
        width: 100%;
        height: max-content;

        .form-container-row{
          width: 100%;
          padding: 0px 25px;
          margin-bottom: 20px;


          .contact-us-form {
            padding-top: 0px;
            margin-top: -32px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
                
            .input {
              width: 100%;
              padding-left: 5px;
              margin-top: 20px;
              border-style: solid;
              border-radius: 6px;
              border-color: #B1B1B1;
              border-width: 1px;
              height: 45px;
              -webkit-appearance: none;
              appearance: none;
              }


              .botton-container{
                translate: 10px 40px;
              }

                .upload-resume-text{
                  margin-top: 20px;
                }

                .file-upload{
                  margin-top: 20px;
                }


                .select-position{
                    height: 45px;
                    margin-top: 20px;
                    cursor: pointer;
                    padding-left: 5px;
                    border-radius: 6px;
                    border-color: #B1B1B1;

                    .option{
                      background-color: rgb(141, 81, 81);
                    }
                }

                .submit-button-container{
                  margin-top: 40px;
                  margin-left: 25px;
                }
                
                .contact-us-form input:focus {
                outline: none;
                }


            .success-message-container{
                    width: 100%;
                    height: 300px;
                    display: flex;

                    div{
                        margin: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: left;

                        .success-message{

                            font-family: Raleway;
                            font-size: 50px;
                            font-weight: 300;
                            line-height: 65px;
                            letter-spacing: 0em;
                            text-align: center;
                            white-space: nowrap;

                            span{
                                font-family: Raleway;
                                font-size: 50px;
                                font-weight: 500;
                                line-height: 65px;
                                letter-spacing: 0em;
                                text-align: center;
                                color: #F3684A;
                            }

                        }

                        .short-message{
                            font-family: Raleway;
                            font-size: 24px;
                            line-height: 31px;
                            letter-spacing: 0em;
                            text-align: left;

                        }
                    }

            }

            }

            .carrer-picture{
              width: 50%;
              display: none;
 
              img{
                margin: auto;
              }
            }
        }
      }

  }