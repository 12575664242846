@use '../../../assets/sass/others/variables' as theme;
@use "sass:map";


.unified-communication {
    
    .introduction-section {
    
        display: flex;
        width: 100%;
        padding-top: 150px;
        padding-bottom: 100px;
        background-color: #F9F9F9;
    
        .section-container {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {

                margin-left: 130px;
                max-width: 600px;
                width: 100%;
    
                .label {
                    font-family: Raleway;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 500;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        color: rgb(243, 104, 74);
                    }
                }
    
                .description {
                    display: flex;
                    flex-direction: row;
                    font-family: Raleway;
                    font-weight: 400;
                    margin-top: 40px;
                    font-size: 18px;
                    line-height: 130%;
                    color: rgba(0, 0, 0, 0.782);
                }
    
                .container-image {
                    display: none;
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                }
    
                .circle-decoration:nth-child(1) {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                    right: 0px;
                    transform: scale(-1);
                }
    
                .circle-decoration:nth-child(2) {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    bottom: 70px;
                    left: -15px;
                }
    
                .display-image {
                    position: relative;
                    margin-left: auto;
                    clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    height: auto;
                    width: 750px;
                }
            }

            .botton-container {
                margin-top: 60px !important;
            }
        }
    
    }

    .points-section{

        display: flex;
        width: 100%;
        padding: 100px 0px 100px 0px;
        background-color: rgb(254, 254, 254);

        .section-container {
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: 1600px;
            flex-direction: row;
            position: relative;


            .bullet-point-container{
                margin-left: 130px;
                margin-right: 100px;
                display: flex;
                flex-direction: column;

                .bullet-item{
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 10px;

                    .icon-container{
                        display: flex;
                        flex-direction: column;

                        .buttet-icon{
                            margin-top: 6px;
                            margin-bottom: auto;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    .bullet-text{
                        margin-left: 8px;
                        font-family: Raleway;
                        font-size: 18px;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                &:nth-child(2){
                    margin-left: 30px;
                }

            }

        



        }

    }

    .let-connect-us{
        .container{
            display: flex;
            flex-direction: column;
            .title-container{
                width: 100%;
            }
 
            .let-connect-title{
                height: auto;
              
                .title{
                    margin: auto;
                    font-size: 42px;
                    text-align: center;

                }
                width: 100%;
                display: block;
            }

            .button-container{
                transform: translateY(-80px);
                width: 100%;
                .button-center{

                    margin: auto;
                }
            }
        
        }
       
    }
    
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .unified-communication {
    
        .introduction-section {
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;

        
            .section-container {
                margin: auto;
                width: 100%;
                height:  350px;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'lg');
                flex-direction: row;
                position: relative;

                .container-content {
                    padding-left: 0px;
                    min-width: 440px;
                    width: 100%;
                    margin: auto;
        
                    .label {
                        font-family: Raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 130%;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 60px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .description-second {
                        font-family: Raleway;
                        font-weight: 400;
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
        
                }
        
                .container-image {
                    height: auto;
                    display: flex;
        
                    .image-container {
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
        
                        .circle-decoration {
                            width: 25px;
                            height: 50px;
                            position: absolute;
                            z-index: 1;
                            top: 50px;
                            right: 0px;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                        }
        
                        .circle-decoration:nth-child(2) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            z-index: 0;
                            left: -20px;
                            bottom: 10px;
                        }
        
                        .display-image {
                            width: 450px ;
                            height:  350px ;
                            position: relative;
                            margin-left: auto;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        }
                    }
                }
        
            }
        }

        .points-section{

            display: flex;
            width: 100%;
            padding: 100px 0px 100px 0px;
            background-color: rgb(254, 254, 254);
    
            .section-container {
                margin: auto;
                width: 100%;
                height: fit-content;
                display: flex;
                max-width: 1600px;
                flex-direction: row;
                position: relative;
    
    
                .bullet-point-container{
                    margin-left: 130px;
                    margin-right: 100px;
                    display: flex;
                    flex-direction: column;
    
                    .bullet-item{
                        display: flex;
                        flex-direction: row;
                        padding-bottom: 10px;
    
                        .icon-container{
                            display: flex;
                            flex-direction: column;
    
                            .buttet-icon{
                                margin-top: 6px;
                                margin-bottom: auto;
                                width: 15px;
                                height: 15px;
                            }
                        }
    
                        .bullet-text{
                            margin-left: 8px;
                            font-family: Raleway;
                            font-size: 18px;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
    
                    &:nth-child(2){
                        margin-left: 30px;
                    }
    
                }
    
            
    
    
    
            }
    
        }

        .let-connect-us{
            .container{
                display: flex;
                flex-direction: column;
                .title-container{
                    width: 100%;
                }
     
                .let-connect-title{
                    height: auto;
                  
                    .title{
                        margin: auto;
                        font-size: 42px;
                        text-align: center;
    
                    }
                    width: 100%;
                    display: block;
                }
    
                .button-container{
                    transform: translateY(-80px);
                    width: 100%;
                    .button-center{
    
                        margin: auto;
                    }
                }
            
            }
           
        }
    
    }

}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .unified-communication {
    
    .introduction-section {

        display: flex;
        width: 100%;
        padding: 80px 20px 20px 20px;
        background-color: map.get(theme.$colors, "white");

        .section-container {
            width: 100%;
            max-width: map-get(theme.$brakpoint, 'sm');
            height: max-content;
            display: flex;
            flex-direction: row;
            margin: auto;

            .container-content {
                margin-left: 0px;
                padding-left: 0px;
                min-width: auto;

                .label {
                    font-family: Raleway;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.16em;
                    text-align: left;
                }

                .title {
                    font-family: Raleway;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 34px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgb(90, 83, 107);

                    span {
                        color: rgb(243, 104, 74);
                    }
                }

                .description {
                    font-family: Raleway;
                    font-weight: 500;
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.6);
                }

                .container-image {
                    margin-left: auto;
                    width: 100%;
                    display: flex;

                    .image-container {
                        height: 237px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                    }

                    .circle-decoration:nth-child(1) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 150px;
                        right: -35px;
                        display: none;
                    }

                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        bottom: 70px;
                        left: -15px;
                        display: none;
                    }

                    .display-image {
                        width: 100% ;
                        height:  100% ;
                        position: relative;
                        margin-left: 0px;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                    }
                }
            }

            .container-image {
                display: none;
            }
        }

    }

    .points-section{

        display: flex;
        width: 100%;
        padding: 64px 20px 64px 20px;
        background-color: rgb(254, 254, 254);

        .section-container {
            margin: auto;
            width: 100%;
            height: fit-content;
            display: flex;
            max-width: 1600px;
            flex-direction: column;
            position: relative;


            .bullet-point-container{
                margin-left: 0px;
                margin-right: 0px;
                display: flex;
                flex-direction: column;

                .bullet-item{
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 10px;

                    .icon-container{
                        display: flex;
                        flex-direction: column;

                        .buttet-icon{
                            margin-top: 6px;
                            margin-bottom: auto;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    .bullet-text{
                        margin-left: 8px;
                        font-family: Raleway;
                        font-size: 16px;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                &:nth-child(2){
                    margin-left: 0px;
                }

            }

        



        }

    }
    
    .let-connect-us{
        .container{
            display: flex;
            flex-direction: column;
            .title-container{
                width: 100%;
            }
    
            .let-connect-title{
                height: auto;
                
                .title{
                    margin: auto;
                    font-size: 28px;
                    text-align: center;

                }
                width: 100%;
                display: block;
            }

            .button-container{
                transform: translateY(30px);
                width: 100%;
                .button-center{

                    margin: auto;
                }
            }
        
        }
        
    }
    
    }

}